import { transformNullToString } from './../../../shared/transformStringValue';
import * as Yup from 'yup';
import { transformNumberValue, transformNumberValueAllowZero } from '../../../shared/transformNumberValue';
import { testNumberWithDotLength } from '../../../shared/testNumberWithDotLength';

export const formSchema = Yup.object().shape({
    resourceSourceId: Yup.number().transform(transformNumberValueAllowZero).typeError('Please select source').min(0, 'Please select source').required().integer(),
    code: Yup.string().nullable(true),
    resourceName: Yup.string().required('Please select resource').nullable(true),
    countryId: Yup.number().transform(transformNumberValueAllowZero).typeError('Please select country').min(0, 'Please select country').required().integer(),
    regionIdentifierId: Yup.number().typeError('Please select region').min(1, 'Please select region').required().integer(),
    referenceRegionIdentifier: Yup.number().typeError('Please select region').min(1, 'Please select region').required('Reference Region IdentifierId is required').integer(),
    referenceQuantity: Yup.number().typeError('Field accepts numbers only').min(1, 'Ref Quantity can`t be less than 1').max(1, 'Ref Quantitys can`t be bigger than 1').required('Ref. Quantity is required'),
    processName: Yup.string().nullable(true),
    description: Yup.string().transform(transformNullToString),
    comment: Yup.string().transform(transformNullToString),
    carbon: Yup.number().transform(transformNumberValueAllowZero).typeError('Please enter Carbon value').required('Carbon is required').test('len', 'Max symbols allowed - 32. Max symbols after dot - 16', testNumberWithDotLength),
    waterUsage: Yup.number().transform(transformNumberValueAllowZero).typeError('Please enter Water usage value').required('Water usage is required').test('len', 'Max symbols allowed - 32. Max symbols after dot - 16', testNumberWithDotLength),
    eutrophication: Yup.number().transform(transformNumberValueAllowZero).typeError('Please enter Eutrophication value').required('Eutrophication is required').test('len', 'Max symbols allowed - 32. Max symbols after dot - 16', testNumberWithDotLength),
    secondaryDataReferenceSourceType: Yup.number().typeError('Please select Secondary data sources').min(0, 'Please select sources').required().integer(),
    referenceDataPublicationYear: Yup.number().transform(transformNumberValue).typeError('Please enter Reference data publication year').min(1900, 'Year can`t be less than 1900').max(2200, 'Year can`t be greater than 2200').required('Reference data publication year field is required!'),
    referencesSamplingYear: Yup.number().typeError('Please enter Reference data publication year').min(1900, 'Year can`t be less than 1900').max(2200, 'Year can`t be greater than 2200').nullable(true),
});
