import { FC, useEffect, useState } from "react";
import { FormDialog, Button, Checkbox, Label } from "@mondra/ui-components";
import InputItem from "../../../../components/InputItem";
import { useFormik } from "formik";
import { ingredientSchema } from "../utils";
import { addLocalPopUpNote } from "../../../../shared/noteFunctions";
import { sortDropdown } from "../../../../shared/formFunctions";
import { getProcessedDropdowns, getProduceDropdowns, getProductDropdowns, getProduct, getSpecialDropdowns } from "../api";
import { Dropdown, IAttributes, IGpcAttribute, IGpcBrick } from "../types";
import AttributeSelector from "./AttributeSelector";

interface ComponentFormProps {
  addProcess: (variables?: any, array?: string) => void;
  configuration: any
}

const IngredientsForm: FC<ComponentFormProps> = ({
  addProcess,
  configuration
}) => {
  const [openForm, setOpenForm] = useState(false);
  const [productType, setProductType] = useState(-1);

  const [attributes, setAttributes] = useState<[] | IAttributes[]>([]);
  const [brick, setBrick] = useState<IGpcBrick>()
  const [gpcAttributes, setGpcAttributes] = useState<[] | IGpcAttribute[]>([]);
  
  const [segment, setSegment] = useState<[] | Dropdown[]>([]);
  const [family, setFamily] = useState<[] | Dropdown[]>([]);
  const [productClass, setProductClass] = useState<[] | Dropdown[]>([]);
  const [activity, setActivity] = useState<[] | Dropdown[]>([]);
  const [classes, setClasses] = useState<[] | Dropdown[]>([]);
  const [category, setCategory] = useState<[] | Dropdown[]>([]);
  const [subcategory, setSubcategory] = useState<[] | Dropdown[]>([]);
  const [functionalUnit, setFunctionalUnit] = useState<[] | Dropdown[]>([]);
  
  const initialValues = {
    ingredientType: "",
    family: "",
    productClass: "",
    categogy: "",
    subCategory: "",
    functionalUnit: "",
    activity: "",
    activityId: 0,
    inputIngredientType: null,
    inputIngredientId: null,
    inputIngredientAttributes: [],
    quantity: '',
    quantityNotes: [],
    correspondingProcess: "",
    includeInTotalIngredientMass: false
  };

  const formik = useFormik({
    initialValues,
    validationSchema: ingredientSchema,
    onSubmit: (values) => {
      if(gpcAttributes.find(p => !p.gpcValueId)){
        return
      }
      addProcess({...values, inputIngredientAttributes: gpcAttributes}, 'ingredients');
      setTimeout(() => setOpenForm(false), 500);
    },
  });

  const onCreateComponent = () => {
    setOpenForm(true);
    setAttributes([])
    setGpcAttributes([])
    formik.setValues(initialValues);
    formik.setTouched({}, false);
  };

  useEffect(()=>{
    if(classes.length > 0){
      getProcessedDropdowns(
        `activity?classId=${classes[0]?.id}`
      ).then((res) => setActivity(res.data));
    }  
  }, [classes])

  return (
    <>
      <Button
        title="Create component"
        iconType="add"
        variant="primary"
        className={"mt-5"}
        onClick={onCreateComponent}
      >
        Create ingredient
      </Button>
      <FormDialog
       className="sm:max-w-3xl px-0 sm:px-0 py-0 sm:py-0"
        title="Create ingredient"
        formId="component"
        onClose={() => setTimeout(() => setOpenForm(false), 500)}
        primaryBtnText="Create"
        onPrimaryButtonClick={formik.handleSubmit}
        open={openForm}
      >
        <form onSubmit={formik.handleSubmit}>
          <div className="piece-content-inputs">
          <InputItem
              name="Ingredient type"
              description="Please specify the product type of this entry"
              type="select"
              formikName="inputIngredientType"
              create
              formik={formik}
              defaultValue={formik.values.inputIngredientType}
              showAsterisk
              onChange={(e) => {
                var value = +(e.target as any).value
                formik.setFieldValue(
                  "ingredientType",
                  configuration.inputIngredientTypes.find((el) => +(e.target as any).value === el.id)
                    ?.description
                );
                setProductType(value);
                setCategory([])
                setSubcategory([])
                setFunctionalUnit([])
                if(value === 1){
                  getProductDropdowns("segment")
                      .then((res) => {
                        setSegment(res.data)
                        if(res?.data[0]?.id){
                          getProductDropdowns(`family?segmentId=${res?.data[0]?.id}`)
                          .then((res) => {
                            setFamily(res.data)
                          })
                        }
                      })
                } else if(value === 3){
                  getSpecialDropdowns("class")
                      .then((res) => setClasses(res.data))
                      .then((res) => getSpecialDropdowns("category?classId=1"))
                      .then((res) => setCategory(res.data));
                } else {
                  getProduceDropdowns("class")
                      .then((res) => setClasses(res.data))
                      .then((res) =>
                      getProduceDropdowns("category?classId=1")
                      )
                      .then((res) => setCategory(res.data))
                }
                formik.setFieldValue("inputIngredientType", value);
              }}
              options={sortDropdown(
                configuration.inputIngredientTypes,
                0,
                "description"
              )}
            />
            {productType === 2 && <InputItem
              name="Activity"
              description=""
              type="select"
              create={true}
              formik={formik}
              showAsterisk
              disabled={activity.length === 0}
              defaultValue={formik.values.activity}
              onChange={(e) => {
                const selectedActivity = activity.find((el) => (e.target as any).value === el.name)
                formik.setFieldValue(
                  "activity",
                  selectedActivity?.name
                );
                formik.setFieldValue("activityId", selectedActivity?.id);
              }}
              options={activity.map((el) => ({
                label: el.name,
                value: el.name,
              }))}
              formikName="activityId"
            />
            }
            {productType === 1 && <InputItem
              name="Family"
              description=""
              type="select"
              create={true}
              formik={formik}
              showAsterisk
              disabled={segment.length === 0}
              defaultValue={formik.values.family}
              onChange={(e) => {
                const selectedFamily = family.find((el) => (e.target as any).value === el.name)
                formik.setFieldValue(
                  "family",selectedFamily?.name
                );
                getProductDropdowns(`class?familyId=${selectedFamily?.id}`)
                          .then((res) => {
                            setProductClass(res.data)
                          })
              }}
              options={family.map((el) => ({
                label: el.name,
                value: el.name,
              }))}
            />
            }
            {productType === 1 && <InputItem
              name="Product class"
              description=""
              type="select"
              create={true}
              formik={formik}
              showAsterisk
              disabled={family.length === 0}
              defaultValue={formik.values.productClass}
              onChange={(e) => {
                const selectedProductClass = productClass.find((el) => (e.target as any).value === el.name)
                formik.setFieldValue(
                  "productClass",
                  selectedProductClass?.name
                );
                getProductDropdowns(`brick?classId=${selectedProductClass?.id}`)
                          .then((res) => {
                            setFunctionalUnit(res.data)
                          })
              }}
              options={productClass.map((el) => ({
                label: el.name,
                value: el.name,
              }))}
            />
            }
            {productType !== 1 && <InputItem
                  name="Category"
                  formikName="categogy"
                  description=""
                  type="select"
                  create={true}
                  disabled={category.length === 0}
                  defaultValue={formik.values.categogy}
                  onChange={(e) => {
                    const categorySelected = category.find((el) => (e.target as any).value === el.name)
                    formik.setFieldValue(
                      "categogy",
                      categorySelected?.name
                    );
                    if(productType == 3){
                      getSpecialDropdowns(
                        `functionalUnit?categoryId=${categorySelected?.id}`
                      ).then((res) => setFunctionalUnit(res.data))
                    } else {
                      getProduceDropdowns(
                        `subcategory?categoryId=${categorySelected?.id}`
                      ).then((res) => setSubcategory(res.data));  
                    }
                  }}
                  options={category.map((el) => ({
                    label: el.name,
                    value: el.name,
                  }))}
                />}
                {productType != 1 && productType != 3 &&(
                  <InputItem
                    name="Sub category"
                    description=""
                    type="select"
                    create={true}
                    disabled={subcategory.length === 0}
                    defaultValue={formik.values.subCategory}
                    onChange={(e) => {
                      const subCatSelected = subcategory.find((el) => (e.target as any).value === el.name)
                      formik.setFieldValue(
                        "subCategory",
                        subCatSelected
                          ?.name
                      );
                      if(productType == 0){
                        getProduceDropdowns(
                          `functionalUnit?subcategoryId=${
                            subCatSelected?.id
                          }`
                        ).then((res) => setFunctionalUnit(res.data));
                      } else {
                        getProcessedDropdowns(
                          `functionalUnit?produceSubcategoryId=${subCatSelected?.id}&activityId=${formik.values.activityId}`
                        ).then((res) => setFunctionalUnit(res.data));
                      }
                    }}
                    options={subcategory.map((el) => ({
                      label: el.name,
                      value: el.name,
                    }))}
                  />
                )}
                <InputItem
                  name={ productType === 1 ? "Brick Name" : "Ingredient Name"}
                  description="Please select a suitable product Name from the Ingredient taxonomy."
                  type="select"
                  create={true}
                  disabled={functionalUnit.length === 0}
                  defaultValue={formik.values.functionalUnit}
                  onChange={(e)=>{
                    const selectedFunctionalUnit = functionalUnit.find((el) => (e.target as any).value === el.name)
                    if(productType === 1){
                      getProduct(`${selectedFunctionalUnit?.id}`).then((res) => setBrick(res.data));
                    }
                    setGpcAttributes([])
                    formik.setFieldValue(
                      "functionalUnit",
                      selectedFunctionalUnit?.name
                    );
                    formik.setFieldValue(
                      "inputIngredientId",
                      selectedFunctionalUnit?.id
                    );
                  }}
                  formikName="inputIngredientId"
                  showAsterisk
                  options={functionalUnit.map((el) => ({
                    label: el.name,
                    value: el.name,
                  }))}
                />
                {
                  brick && attributes.length > 0 &&
                  <AttributeSelector
                    brick={brick}
                    attributes={brick.attributes}
                    gpcAttributes={gpcAttributes}
                    setGpcAttributes={setGpcAttributes}
                  />
                }
            <InputItem
              name="Quantity"
              description="Please provide the quantity of the ingredient in kg"
              type="input"
              formik={formik}
              showAsterisk
              onChange={formik.handleChange}
              defaultValue={formik.values.quantity}
              addonTitle="kg"
              formikName="quantity"
              addLocal={(params) => 
                addLocalPopUpNote({ 
                  formik,
                  index: formik.values.quantityNotes.length,
                  ...params
                })
              }
              notes={formik.values.quantityNotes || []}
            />
            <InputItem
              name="Corresponding Process"
              description="Please review the excel file called “Mondra_ProcessingActivities_Ontology” to list the corresponding process for this ingredient E.g. Mixing, Shaping, Cream Filling, Baking, Cleaning, Cooling."
              type="input"
              formik={formik}
              onChange={formik.handleChange}
              defaultValue={formik.values.correspondingProcess}
              formikName="correspondingProcess"
            />
             <div className="inputItem mx-1 mt-6">
              <div className="flex justify-start items-center gap-3">
                <Label
                  className={`w-72 flex-wrap`}
                  title="Include in batch input weight"
                >
                  <Checkbox
                    id = "include-input-batch"
                    key="include-input-batch"
                    checked={formik.values.includeInTotalIngredientMass}
                    onChange={(e) => {
                      formik.setFieldValue("includeInTotalIngredientMass", e.currentTarget.checked)
                      formik.handleChange(e);
                    }}
                    size={18}
                  />
                  <span className="mx-2">Include in batch input weight</span>
                </Label>
              </div>
            </div>
          </div>
        </form>
      </FormDialog>
    </>
  );
};

export default IngredientsForm;
