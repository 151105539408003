import React, { FC, useCallback, useEffect, useState, EffectCallback, useRef } from "react";
import { useAppDispatch } from "../../../hooks/redux";
import { useSearchParams, useNavigate } from "react-router-dom";
import { Breadcrumbs, Button, LottieLoader, Notification } from "@mondra/ui-components";
import { useAppSelector } from "../../../hooks/redux";
import { useFormik } from "formik";
import { fetchFactor, getConfiguration } from "./emissionFactorsActions";
import { showCorrelationIdError } from "../../../redux/correlationIdSlice";
import {
  getDefaults,
  multipleObject,
  sortDropdown,
  transformFormValues,
} from "../../../shared/formFunctions";
import { copyToClipboard, formSchema } from "./utils";
import Select from "react-select";
import {
  updateEmissionFactors,
  publishEmissionFactors,
  unPublishEmissionFactors,
  createEmissionFactors,
  getProcessedDropdowns,
  getSpecialDropdowns,
} from "./api";

import { usePrompt } from "../../../hooks/usePrompt";
import { addLocalPopUpNote } from "../../../shared/noteFunctions";
import {
  handlePublishStatus,
  setDefaultFactor,
} from "../../../redux/factorSlice";

import InputItem from "../../../components/InputItem";
import "./index.scss";
import ActivityRightPanel from "../../../components/ActivityRightPanel";
import { ActivityTypes } from "../../../const";

interface Dropdown {
  id: number;
  code: string;
  name: string;
  level: number;
  parentCode: string;
}

interface IProxyProduceDetails {
  class: number
  category: number
  productGroup: number
}

const defaultProxyProduceDetails = {
  class: -1,
  category: -1,
  productGroup: -1
}

const Factor: FC = ({}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const { loading, factor, configuration, configurationLoading } =
    useAppSelector((state) => state.factorSlice);
  const { loading: companiesLoading } = useAppSelector(
    (state) => state.companiesSlice
  );
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [showError, setShowError] = useState<string>("");
  const [errors, setErrors] = useState<[any, string[]][]>();
  const [showPublished, setShowPublished] = useState<boolean>(false);

  const [selectedClass, setSelectedClass] = useState<number>(-1)
  const [selectedCategory, setSelectedCategory] = useState<number>(-1)
  const [selectedProductGroup, setSelectedProductGroup] = useState<number>(-1)

  const [selectedProxyProduce, setSelectedProxyProduce] = useState<IProxyProduceDetails>(defaultProxyProduceDetails)

  const [classes, setClasses] = useState<[] | Dropdown[]>([]);
  const [category, setCategory] = useState<[] | Dropdown[]>([]);
  const [subcategory, setSubcategory] = useState<[] | Dropdown[]>([]);
  const [functionalUnit, setFunctionalUnit] = useState<[] | Dropdown[]>([]);

  const [proxyCategory, setProxyCategory] = useState<[] | Dropdown[]>([]);
  const [proxySubcategory, setProxySubcategory] = useState<[] | Dropdown[]>([]);
  const [proxyFunctionalUnit, setProxyFunctionalUnit] = useState<
    [] | Dropdown[]
  >([]);
  const prevProductType = useRef<number>(factor.productType);
  const blocker = useRef<number | null>(null);
  const isCustomEF = useRef<boolean>(false);

  const factorId = searchParams.get("itemId") || "";
  const isNew = factorId === "new"

  useEffect((): ReturnType<EffectCallback> => {
    if(!companiesLoading){
      if (factorId == "") {
        navigate("/not-found");
      }
      if (isNew) {
        dispatch(getConfiguration("0"));
      } else {
        dispatch(getConfiguration(factorId));
      }
      if (!isNew) {
        dispatch(fetchFactor(factorId, () => navigate("/emission-factors")));
      }
      return () => {
        dispatch(setDefaultFactor());
      };
    }
  }, [companiesLoading, isNew]);

  useEffect(() => {
    formik.setValues({ ...factor });
  }, [factor]);

  useEffect(() => {
    if(configuration.productTaxonomies.functionalUnits !== null)
    {
      const classFromTaxonomy = configuration.productTaxonomies.classes?.[0]
      const subCategoryIdFromTaxonomy = configuration.productTaxonomies.functionalUnits.find(
        (el) => el.id === factor.productId
      )?.subcategoryId
      const subCategoryFromTaxonomy = configuration.productTaxonomies.subcategories.filter(
        (el) =>
          el.id ===
          subCategoryIdFromTaxonomy
      )[0]
      setSelectedClass(classFromTaxonomy?.id || -1)
      setSelectedCategory(subCategoryFromTaxonomy?.categoryId || -1)
      setSelectedProductGroup(subCategoryFromTaxonomy?.id || -1)
    }
    if(configuration.specialTaxonomies.functionalUnits !== null){
      const classFromTaxonomy = configuration.specialTaxonomies.classes?.[0]
      const categoryIdFromTaxonomy = configuration.specialTaxonomies.functionalUnits.find(
        (el) => el.id === factor.productId
      )?.categoryId
      setSelectedClass(classFromTaxonomy?.id || -1)
      setSelectedCategory(categoryIdFromTaxonomy || -1)
    }

  }, [factor.productId, configuration.productTaxonomies, configuration.specialTaxonomies])

  useEffect(() => {
    if(configuration.eutrophicationProxyProduceTaxonomies 
      && factor.proxyProduceId !== null 
      && factor.proxyProduceId !== undefined)
    {
      const proxyClassId =  configuration.eutrophicationProxyProduceTaxonomies?.classes?.[0]?.id || -1
      const proxyProductGroupId = configuration.eutrophicationProxyProduceTaxonomies.functionalUnits?.find(
        (el) =>
          el.id === factor.proxyProduceId
      )?.subcategoryId || -1
      const proxyCategoryId = configuration.eutrophicationProxyProduceTaxonomies.subcategories?.filter(
        (el) =>
          el.id ===proxyProductGroupId)[0]?.categoryId || -1
      setSelectedProxyProduce({
        class: proxyClassId,
        category: proxyCategoryId,
        productGroup: proxyProductGroupId
      })
    }
  }, [factor.proxyProduceId, configuration.eutrophicationProxyProduceTaxonomies])


  const formik = useFormik({
    initialValues: {
      ...factor,
    },
    validationSchema: formSchema,
    onSubmit: async () => {
      if (factor.state === 0) {
        publishEmissionFactors(factorId)
          .then(() => {
            dispatch(handlePublishStatus(1));
            setShowPublished(true);
            setTimeout(() => {
              setShowPublished(false);
            }, 4000);
          })
          .catch((error) =>
            setShowError(error.response.data.title)
          );
      } else {
        unPublishEmissionFactors(factorId)
          .then(() => {
            dispatch(handlePublishStatus(0));
            setShowPublished(true);
            setTimeout(() => {
              setShowPublished(false);
            }, 4000);
          })
          .catch((error) =>
            setShowError(error.response.data.title)
          );
      }
    },
  });

  const saveDraft = async (e) => {
    e.preventDefault();
    const {values} = formik;

    const tValues = transformFormValues(values);

    tValues.postHarvestLossesId =
    tValues.postHarvestLossesId != null &&
      (formik.values.functionalUnit === 0 ||
        formik.values.functionalUnit === 5 ||
        formik.values.functionalUnit === 6)
        ? +tValues.postHarvestLossesId
        : null;
    tValues.postHarvestLosses =
        tValues.postHarvestLosses != null &&
      (formik.values.functionalUnit === 0 ||
        formik.values.functionalUnit === 5 ||
        formik.values.functionalUnit === 6)
        ? +tValues.postHarvestLosses
        : null;
    tValues.postHarvestLossesNotes =
      formik.values.functionalUnit === 0 ||
      formik.values.functionalUnit === 5 ||
      formik.values.functionalUnit === 6
        ? tValues.postHarvestLossesNotes
        : [];
    tValues.productionSystem =
      formik.values.productType === 0 && tValues.productionSystem !== null
        ? +tValues.productionSystem
        : null;
    tValues.plantProtectionTypeGroup =
      formik.values.productType === 0 &&
      tValues.plantProtectionTypeGroup !== null
        ? +tValues.plantProtectionTypeGroup
        : null;
    tValues.hestiaProduceId =
      tValues.farmDataCalculator !== 0 ? null : tValues.hestiaProduceId;
    tValues.hestiaSubmissionId =
      tValues.farmDataCalculator !== 0 ? null : tValues.hestiaSubmissionId;
    tValues.externalSource =
      tValues.dataOrigin !== 3 ? null : tValues.externalSource;
    tValues.missingImpactFactor =
      tValues.dataOrigin !== 1 ? null : tValues.missingImpactFactor;
    tValues.farmDataCalculator =
      tValues.farmDataCalculator != null ? +tValues.farmDataCalculator : null;
    tValues.clickUpId = tValues.clickUpId?.trim()
      ? tValues.clickUpId?.trim()
      : null;

    tValues.coreVersionRelease = tValues.coreVersionRelease?.toString()
    tValues.calculatorVersion = tValues.calculatorVersion?.toString()
    
    if (tValues.dataOrigin != 1 && tValues.dataOrigin != 2) {
      tValues.calculatorVersion = null;
      tValues.farmDataCalculator = null;
      tValues.hestiaProduceId = null;
      tValues.hestiaSubmissionId = null;
    }
    
    if(tValues.mondraMAToFillMissingEutrophication === "false") {
      tValues.mondraMAToFillMissingEutrophication = false
    }
    if(tValues.mondraMAToFillMissingEutrophication === "true"){
      tValues.mondraMAToFillMissingEutrophication = true
    }
    if (isNew) {
      try {
        await createEmissionFactors(tValues).then(() => {
          navigate("/emission-factors");
        });
      } catch (error: any) {
        if (error.showCorrelationId) {
          dispatch(showCorrelationIdError(error.CorrelationId));
        } else {
          setShowError(
            typeof error.response.data === "string"
              ? error.response.data
              : typeof error.response.data.errors === "string"
              ? error.response.data.errors
              : Object.values(error.response.data.errors)
                  .toString()
                  .replace(/!,/g, "!\n")
          );
          setTimeout(() => {
            setShowError("");
          }, 5000);
          setErrors(
            typeof error.response.data.errors === "string"
              ? error.response.data.errors
              : Object.entries(error.response.data.errors)
          );
        }
      }
    } else {
      try {
        await updateEmissionFactors(factorId, tValues)
          .then(() => {
            dispatch(
              fetchFactor(factorId, () => navigate("/emission-factors"))
            );
            dispatch(getConfiguration(factorId));
            setClasses([]);
            setCategory([]);
            setSubcategory([]);
            setFunctionalUnit([]);
            setProxyCategory([]);
            setProxySubcategory([]);
            setProxyFunctionalUnit([]);
            setSelectedClass(-1)
            setSelectedCategory(-1)
            setSelectedProductGroup(-1)
            setSelectedProxyProduce(defaultProxyProduceDetails)
            prevProductType.current = values.productType;
          })
          .then(() => {
            setShowNotification(true);
            setTimeout(() => {
              setShowNotification(false);
            }, 5000);
          });
      } catch (error: any) {
        if (error.showCorrelationId) {
          dispatch(showCorrelationIdError(error.CorrelationId));
        } else {
          setShowError(
            typeof error.response.data === "string"
              ? error.response.data
              : typeof error.response.data.errors === "string"
              ? error.response.data.errors
              : Object.values(error.response.data.errors)
                  .toString()
                  .replace(/!,/g, "!\n")
          );
          setTimeout(() => {
            setShowError("");
          }, 5000);
          setErrors(
            typeof error.response.data.errors === "string"
              ? error.response.data.errors
              : Object.entries(error.response.data.errors)
          );
        }
      }
    }
  }

  useEffect(() => {
    isCustomEF.current = formik.values.companyId != null && formik.values.companyId !== '' && formik.values.state === 1;
    if (blocker.current === null) {
      prevProductType.current = formik.values.productType;
    }
  }, [formik.values.productType]);

  usePrompt(
    "There are unsaved changes, do you want to leave?",
    !isNew
      ? JSON.stringify(factor) != JSON.stringify(formik.values)
      : false
  );

  const clickHandler = useCallback(() => {
    copyToClipboard(formik.values.clickUpId || "")
  }, [formik.values.clickUpId]);

  return (loading !== true && configurationLoading !== true) ||
  isNew ? (
    <>
      <div className="piece content">
        {showError !== "" && (
          <div className={"piece-notification"}>
            <Notification type="error" label="Oops:" description={showError} />
          </div>
        )}
        {showPublished && (
          <div className={"piece-notification"}>
            <Notification
              type="success"
              label="Success:"
              description="Status was updated!"
              isToast={false}
            />
          </div>
        )}
        {showNotification && (
          <div className={"piece-notification"}>
            <Notification
              type="success"
              label="Success:"
              description="Item was updated!"
              isToast={false}
            />
          </div>
        )}
        <div className="mt-3 mb-3">
          <Breadcrumbs
            routes={[
              {
                path: "/emission-factors",
                title: "Emission Factors Table",
              },
              {
                path: `/emission-factors/sale?itemId=${factorId}`,
                title: "Emission Factors Item",
              },
            ]}
          />
        </div>
        {!isNew && (
          <>
            <div className="piece-header">
              <div
                className={`piece-header-status ${
                  factor.state === 0 ? "bg-red-500" : "bg-emerald-500"
                }`}
              >
                {factor.state === 0 ? "Not Published" : "Published"}
              </div>
              {factor?.lastPublishedDate ? (
                <span className={"inputItem-header"}>
                  {`Last published date: ${new Date(
                    factor.lastPublishedDate
                  ).toLocaleString()}
                  `}
                </span>
              ) : (
                ""
              )}
            </div>
          </>
        )}
        <p className={"decoration-gray-600 mt-1 mb-7 piece-description"}>
          This database is a collation of environmental impacts for products and
          farm produce. All data will be reported the specified functional
          units.
        </p>
        {!isNew && (
          <>
            {configuration.productTaxonomies.functionalUnits === null &&
              formik.values.productType != 3 && (
                <div className="text-base text-rose-600">
                  There is unpublished changes in product taxonomy: ID{" "}
                  {factor.productId}
                </div>
            )}
            {configuration.specialTaxonomies.functionalUnits === null &&
              formik.values.productType == 3 && (
                <div className="text-base text-rose-600">
                  There is unpublished changes in special taxonomy: ID{" "}
                  {factor.productId}
                </div>
            )}
            {factor.dataOrigin == 1 &&
              configuration.eutrophicationProxyProduceTaxonomies.functionalUnits ===
                null &&
              factor.proxyProduceId !== null && (
                <div className="text-base text-rose-600 mt-3">
                  There is unpublished changes in eutrophication proxy produce
                  taxonomies: ID {factor.proxyProduceId}
                </div>
              )
            }
          </>
        )}
        
        <form onSubmit={formik.handleSubmit} className="piece-content">
          <h5 className={"mt-5"}>Product/Produce information</h5>
          <div className="piece-content-inputs">
            <InputItem
              disabled={isCustomEF.current}
              name="Product type"
              description="Please specify the product type of this entry"
              type="select"
              formikName="productType"
              formik={formik}
              showAsterisk
              defaultValue={formik.values.productType}
              onChange={(e) => {
                blocker.current = 1;
                prevProductType.current = formik.values.productType;
                (e.target as any).value != "3"
                  ? getProcessedDropdowns("class")
                      .then((res) => setClasses(res.data))
                      .then((res) =>
                        getProcessedDropdowns("category?classId=1")
                      )
                      .then((res) => setCategory(res.data))
                  : getSpecialDropdowns("class")
                      .then((res) => setClasses(res.data))
                      .then((res) => getSpecialDropdowns("category?classId=1"))
                      .then((res) => setCategory(res.data));
                formik.setFieldValue("productType", +(e.target as any).value);
                setSelectedClass(-1)
                setSelectedCategory(-1)
                setSelectedProductGroup(-1)
                formik.setFieldValue("productId", -1);
              }}
              options={sortDropdown(
                configuration.productTypeEFs,
                formik.values.productType,
                "description"
              )}
            />
            {isNew ||
            +prevProductType.current !== formik.values.productType ? (
              <>
                <InputItem
                  name="Class"
                  description=""
                  type="select"
                  disabled={classes.length === 0}
                  defaultValue={selectedClass}
                  onChange={(e) => {
                    const classSelected = (e.target as any).value
                    formik.values.productType == 3
                      ? getSpecialDropdowns(
                          `category?classId=${classSelected}`
                        ).then((res) => setCategory(res.data))
                      : getProcessedDropdowns(
                          `category?classId=${classSelected}`
                        ).then((res) => setCategory(res.data));
                    setSelectedClass(+classSelected)
                    setSelectedCategory(-1)
                    setSelectedProductGroup(-1)
                    formik.setFieldValue("productId", -1);
                  }}
                  options={classes.map((el) => ({
                    label: el.name,
                    value: el.id,
                  }))}
                />
                <InputItem
                  name="Category"
                  description=""
                  type="select"
                  defaultValue={selectedCategory}
                  disabled={category.length === 0}
                  onChange={(e) => {
                    const categorySelected = (e.target as any).value
                    formik.values.productType == 3
                      ? getSpecialDropdowns(
                          `functionalUnit?categoryId=${categorySelected}`
                        ).then((res) => setFunctionalUnit(res.data))
                      : getProcessedDropdowns(
                          `subcategory?categoryId=${categorySelected}`
                        ).then((res) => setSubcategory(res.data));
                    setSelectedCategory(+categorySelected)
                    setSelectedProductGroup(-1)
                    formik.setFieldValue("productId", -1);
                  }}
                  options={category.map((el) => ({
                    label: el.name,
                    value: el.id,
                  }))}
                />
                {formik.values.productType != 3 && (
                  <InputItem
                    name="Product Group"
                    description=""
                    type="select"
                    create={true}
                    disabled={subcategory.length === 0}
                    defaultValue={selectedProductGroup}
                    onChange={(e) => {
                      const productGroupSelected = (e.target as any).value
                      getProcessedDropdowns(
                        `functionalUnit?subcategoryId=${productGroupSelected}`
                      ).then((res) => setFunctionalUnit(res.data));
                      setSelectedProductGroup(+productGroupSelected)
                      formik.setFieldValue("productId", -1);
                    }}
                    options={subcategory.map((el) => ({
                      label: el.name,
                      value: el.id,
                    }))}
                  />
                )}
                <InputItem
                  name="Product Name"
                  description="Please select a suitable product Name from the Ingredient taxonomy."
                  type="select"
                  create={true}
                  disabled={functionalUnit.length === 0}
                  onChange={formik.handleChange}
                  defaultValue={formik.values.productId}
                  formikName="productId"
                  showAsterisk
                  options={functionalUnit.map((el) => ({
                    label: el.name,
                    value: el.id,
                  }))}
                />
              </>
            ) : formik.values.productType != 3 ? (
              configuration.productTaxonomies.functionalUnits !== null && (
                <>
                  <InputItem
                    disabled={isCustomEF.current}
                    name="Class"
                    description=""
                    type="select"
                    defaultValue={selectedClass}
                    onChange={(e) => {
                      const classSelected = (e.target as any).value
                      formik.values.productType == 3
                        ? getSpecialDropdowns(
                            `category?classId=${classSelected}`
                          ).then((res) => setCategory(res.data))
                        : getProcessedDropdowns(
                            `category?classId=${classSelected}`
                          ).then((res) => setCategory(res.data));
                      setSelectedClass(+classSelected)
                      setSelectedCategory(-1)
                      setSelectedProductGroup(-1)
                      formik.setFieldValue("productId", -1);
                    }}
                    options={
                      (classes.length !== 0 &&
                        classes.map((el) => ({
                          label: el.name,
                          value: el.id,
                        }))) ||
                      configuration.productTaxonomies.classes.map((el) => ({
                        label: el.name,
                        value: el.id,
                      }))
                    }
                  />
                  <InputItem
                    disabled={isCustomEF.current}
                    name="Category"
                    description=""
                    type="select"
                    defaultValue={selectedCategory}
                    onChange={(e) => {
                      const categorySelected = (e.target as any).value
                      formik.values.productType == 3
                        ? getSpecialDropdowns(
                            `functionalUnit?categoryId=${categorySelected}`
                          ).then((res) => setFunctionalUnit(res.data))
                        : getProcessedDropdowns(
                            `subcategory?categoryId=${categorySelected}`
                          ).then((res) => setSubcategory(res.data));
                        setSelectedCategory(+categorySelected)
                        setSelectedProductGroup(-1)
                        formik.setFieldValue("productId", -1);
                    }}
                    options={
                      category.length !== 0
                        ? category.map((el) => ({
                            label: el.name,
                            value: el.id,
                          }))
                        : sortDropdown(
                            configuration.productTaxonomies.categories,
                            selectedCategory,
                            "name"
                          )
                    }
                  />
                  {formik.values.productType != 3 && (
                    <InputItem
                      disabled={isCustomEF.current}
                      name="Product Group"
                      description=""
                      type="select"
                      defaultValue={selectedProductGroup}
                      onChange={(e) => {
                        const productGroupSelected = (e.target as any).value
                        getProcessedDropdowns(
                          `functionalUnit?subcategoryId=${productGroupSelected}`
                        ).then((res) => setFunctionalUnit(res.data));
                        setSelectedProductGroup(+productGroupSelected)
                        formik.setFieldValue("productId", -1);
                      }}
                      options={
                        subcategory.length !== 0
                          ? subcategory.map((el) => ({
                              label: el.name,
                              value: el.id,
                            }))
                          : sortDropdown(
                              configuration.productTaxonomies.subcategories,
                              selectedProductGroup,
                              "name"
                            )
                      }
                    />
                  )}
                  <InputItem
                    disabled={isCustomEF.current}
                    name="Product Name"
                    description="Please select a suitable product Name from the Ingredient taxonomy."
                    type="select"
                    onChange={formik.handleChange}
                    formikName="productId"
                    defaultValue={formik.values.productId}
                    options={
                      functionalUnit.length !== 0 || subcategory.length !== 0
                        ? functionalUnit.map((el) => ({
                            label: el.name,
                            value: el.id,
                          }))
                        : sortDropdown(
                            configuration.productTaxonomies.functionalUnits,
                            formik.values.productId,
                            "name"
                          )
                    }
                  />
                </>
              )
            ) : (
              configuration.specialTaxonomies.functionalUnits !== null && (
                <>
                  <InputItem
                    disabled={isCustomEF.current}
                    name="Class"
                    description=""
                    type="select"
                    defaultValue={selectedClass}
                    onChange={(e) => {
                      const classSelected = (e.target as any).value
                      formik.values.productType == 3
                        ? getSpecialDropdowns(
                            `category?classId=${classSelected}`
                          ).then((res) => setCategory(res.data))
                        : getProcessedDropdowns(
                            `category?classId=${classSelected}`
                          ).then((res) => setCategory(res.data));
                      setSelectedClass(+classSelected)
                      setSelectedCategory(-1)
                      setSelectedProductGroup(-1)
                      formik.setFieldValue("productId", -1);
                    }}
                    options={
                      (classes.length !== 0 &&
                        classes.map((el) => ({
                          label: el.name,
                          value: el.id,
                        }))) ||
                      configuration.specialTaxonomies.classes.map((el) => ({
                        label: el.name,
                        value: el.id,
                      }))
                    }
                  />
                  <InputItem
                    disabled={isCustomEF.current}
                    name="Category"
                    description=""
                    type="select"
                    defaultValue={selectedCategory}
                    onChange={(e) => {
                      const categorySelected = (e.target as any).value
                      formik.values.productType == 3
                        ? getSpecialDropdowns(
                            `functionalUnit?categoryId=${categorySelected}`
                          ).then((res) => setFunctionalUnit(res.data))
                        : getProcessedDropdowns(
                            `subcategory?categoryId=${categorySelected}`
                          ).then((res) => setSubcategory(res.data));
                        setSelectedCategory(+categorySelected);
                        formik.setFieldValue("productId", -1);
                    }}
                    options={
                      category.length !== 0
                        ? category.map((el) => ({
                            label: el.name,
                            value: el.id,
                          }))
                        : sortDropdown(
                            configuration.specialTaxonomies.categories,
                            selectedCategory,
                            "name"
                          )
                    }
                  />
                  <InputItem
                    disabled={isCustomEF.current}
                    name="Product Name"
                    description="Please select a suitable product Name from the Ingredient taxonomy."
                    type="select"
                    onChange={formik.handleChange}
                    formikName="productId"
                    defaultValue={formik.values.productId}
                    options={
                      functionalUnit.length !== 0
                        ? functionalUnit.map((el) => ({
                            label: el.name,
                            value: el.id,
                          }))
                        : sortDropdown(
                            configuration.specialTaxonomies.functionalUnits,
                            formik.values.productId,
                            "name"
                          )
                    }
                  />
                </>
              )
            )}
            <InputItem
              disabled={isCustomEF.current}
              name="Product Name at Source"
              description="Please provide the original product/produce name from the original source."
              type="input"
              onChange={formik.handleChange}
              formikName="productNameAtSource"
              showAsterisk
              formik={formik}
              defaultValue={formik.values.productNameAtSource}
            />
            <InputItem
              disabled={isCustomEF.current}
              key={
                formik.values.countryId
                  ? formik.values.countryId + Math.random()
                  : Math.random()
              }
              name="Country Name"
              description="The country for which this entry was generated."
              type="select"
              onChange={formik.handleChange}
              formikName="countryId"
              create={true}
              defaultValue={
                formik.values.countryId ? formik.values.countryId : -1
              }
              formik={formik}
              options={sortDropdown(
                configuration.countries,
                formik.values.countryId ?? -1,
                "countryName"
              )}
            />
            <InputItem
              disabled={isCustomEF.current}
              name="Country ISO Code"
              description="Please select the country in which the data was collected."
              type="select"
              create={true}
              defaultValue={
                formik.values.countryId ? formik.values.countryId : -1
              }
              onChange={formik.handleChange}
              formik={formik}
              formikName="countryId"
              options={sortDropdown(
                configuration.countries,
                formik.values.countryId ?? -1,
                "countryISO"
              )}
            />
            <InputItem
              disabled={isCustomEF.current}
              name="Region/State Name"
              description="Please select the Region/State if known."
              type="input"
              onChange={formik.handleChange}
              formikName="regionStateName"
              formik={formik}
              defaultValue={formik.values.regionStateName}
            />
            <InputItem
              disabled={isCustomEF.current}
              name="Region Identifier"
              description="Please select the region identifier for this entry."
              type="select"
              defaultValue={formik.values.regionIdentifierId}
              onChange={formik.handleChange}
              formikName="regionIdentifierId"
              formik={formik}
              showAsterisk
              options={sortDropdown(
                configuration.regions,
                formik.values.regionIdentifierId,
                "name"
              )}
            />
            {formik.values.productType === 0 && (
              <div className="inputItem">
                <h3 className="mt-5">
                  Production Systems <span className="text-red-500">*</span>
                </h3>
                <p className={"decoration-gray-600 mb-3"}>
                  Please select the production system for this record.
                </p>
                <Select
                  isDisabled={isCustomEF.current}
                  isClearable={false}
                  defaultValue={[
                    ...getDefaults(
                      factor.productionSystem,
                      configuration.productionSystems
                    ),
                  ]}
                  name="productionSystem"
                  onChange={(newValue: any, action: any) => {
                    action.action === "remove-value" &&
                      formik.setFieldValue(
                        "productionSystem",
                        formik.values.productionSystem !== null
                          ? (formik.values.productionSystem -=
                              action.removedValue.value)
                          : null
                      );
                    action.action === "select-option" &&
                      formik.setFieldValue(
                        "productionSystem",
                        (formik.values.productionSystem += action.option.value)
                      );
                  }}
                  isMulti
                  className="basic-multi-select"
                  options={multipleObject(configuration.productionSystems)}
                />
                {formik.touched.productionSystem &&
                formik.errors.productionSystem ? (
                  <span className="formik-error">
                    {formik.errors.productionSystem}
                  </span>
                ) : (
                  ""
                )}
              </div>
            )}
            {formik.values.productType === 0 && (
              <div className="inputItem">
                <h3 className="mt-5 whitespace-nowrap">
                  Plant Protection Type Group{" "}
                  <span className="text-red-500">*</span>
                </h3>
                <p className={"decoration-gray-600 mb-3"}>
                  Please select the plant protection system for this record.
                  Uncovered = no use of any plant protection, Covered = any
                  plant protection used as cover
                </p>
                <Select                  
                  isDisabled={isCustomEF.current}
                  isClearable={false}
                  defaultValue={[
                    ...getDefaults(
                      factor.plantProtectionTypeGroup,
                      configuration.plantProtectionTypeGroups
                    ),
                  ]}
                  name="plantProtectionTypeGroup"
                  onChange={(newValue: any, action: any) => {
                    action.action === "remove-value" &&
                      formik.setFieldValue(
                        "plantProtectionTypeGroup",
                        formik.values.plantProtectionTypeGroup !== null
                          ? (formik.values.plantProtectionTypeGroup -=
                              action.removedValue.value)
                          : null
                      );
                    action.action === "select-option" &&
                      formik.setFieldValue(
                        "plantProtectionTypeGroup",
                        (formik.values.plantProtectionTypeGroup +=
                          action.option.value)
                      );
                  }}
                  isMulti
                  className="basic-multi-select"
                  options={multipleObject(
                    configuration.plantProtectionTypeGroups
                  )}
                />
                {formik.touched.plantProtectionTypeGroup &&
                formik.errors.plantProtectionTypeGroup ? (
                  <span className="formik-error">
                    {formik.errors.plantProtectionTypeGroup}
                  </span>
                ) : (
                  ""
                )}
              </div>
            )}
            {(formik.values.plantProtectionTypeGroup == 1 ||
              formik.values.plantProtectionTypeGroup == 3) && (
              <div className="inputItem">
                <h3 className="mt-5">Plant Protection Type</h3>
                <p className={"decoration-gray-600 mb-3"}>
                  Please select the plant protection type for this record.
                </p>
                <Select                
                  isDisabled={isCustomEF.current}
                  isClearable={false}
                  defaultValue={[
                    ...getDefaults(
                      factor.plantProtectionType,
                      configuration.plantProtectionTypes
                    ),
                  ]}
                  name="plantProtectionType"
                  onChange={(newValue: any, action: any) => {
                    formik.values.plantProtectionType =
                      formik.values.plantProtectionType === null
                        ? 0
                        : formik.values.plantProtectionType;
                    action.action === "remove-value" &&
                      formik.setFieldValue(
                        "plantProtectionType",
                        (formik.values.plantProtectionType -=
                          action.removedValue.value)
                      );
                    action.action === "select-option" &&
                      formik.setFieldValue(
                        "plantProtectionType",
                        (formik.values.plantProtectionType +=
                          action.option.value)
                      );
                  }}
                  isMulti
                  className="basic-multi-select"
                  options={multipleObject(configuration.plantProtectionTypes)}
                />
                {formik.touched.plantProtectionType &&
                formik.errors.plantProtectionType ? (
                  <span className="formik-error">
                    {formik.errors.plantProtectionType}
                  </span>
                ) : (
                  ""
                )}
              </div>
            )}
            <InputItem
              disabled={isCustomEF.current}
              name="Functional Unit"
              description=""
              type="select"
              defaultValue={formik.values.functionalUnit}
              onChange={formik.handleChange}
              formikName="functionalUnit"
              formik={formik}
              showAsterisk
              options={sortDropdown(
                configuration.functionalUnits,
                formik.values.functionalUnit,
                "description"
              )}
            />
            <InputItem
              name="External code"
              description=""
              type="input"
              formik={formik}
              disabled
              onChange={formik.handleChange}
              defaultValue={formik.values.code || ""}
              formikName="code"
            />
          </div>
          <h5 className={"mt-5"}>Data Origin</h5>
          <div className="piece-content-inputs">
            <InputItem
              disabled={isCustomEF.current}
              name="Data Origin"
              description=""
              type="select"
              defaultValue={formik.values.dataOrigin}
              onChange={formik.handleChange}
              formikName="dataOrigin"
              showAsterisk
              formik={formik}
              options={sortDropdown(
                configuration.dataOrigins,
                formik.values.dataOrigin,
                "description"
              )}
            />
            <InputItem
              disabled
              name="Data Quality Completeness Score"
              description="Read only"
              type="input"
              formik={formik}
              defaultValue={formik.values.dataQualityCompletenessScore || ""}
              formikName="dataQualityCompletenessScore"
            />
            <InputItem
              disabled={isCustomEF.current}
              name="Core Version Release"
              description="“Core 1.0” release in 2022."
              type="input"
              onChange={formik.handleChange}
              formikName="coreVersionRelease"
              formik={formik}
              showAsterisk
              defaultValue={formik.values.coreVersionRelease}
            />
            {formik.values.dataOrigin == 3 && (
              <InputItem
                disabled={isCustomEF.current}
                name="External Source"
                description="Please specify if the source is not Mondra Core Platform, PnN Original Publication, Mondra MA."
                type="input"
                onChange={formik.handleChange}
                formikName="externalSource"
                showAsterisk
                formik={formik}
                defaultValue={
                  formik.values.dataOrigin == 3
                    ? formik.values.externalSource || ""
                    : undefined
                }
              />
            )}
          </div>
          {(formik.values.dataOrigin == 1 || formik.values.dataOrigin == 2) && (
            <h5 className={"mt-5"}>Data Origin Farm</h5>
          )}
          <div className="piece-content-inputs">
            {(formik.values.dataOrigin == 1 ||
              formik.values.dataOrigin == 2) && (
              <InputItem
                disabled={isCustomEF.current}
                name="Farm Data Calculator"
                description=""
                type="select"
                defaultValue={formik.values.farmDataCalculator}
                onChange={formik.handleChange}
                formikName="farmDataCalculator"
                showAsterisk
                create={factorId == "new" || factor.farmDataCalculator == null}
                formik={formik}
                options={sortDropdown(
                  configuration.farmDataCalculators,
                  formik.values.farmDataCalculator || -1,
                  "description"
                )}
              />
            )}
            {formik.values.farmDataCalculator == 0 && (
              <InputItem
                disabled={isCustomEF.current}
                name="Hestia Produce ID"
                description="Please provide the Hestia produce ID that was used for the run."
                type="input"
                onChange={formik.handleChange}
                formikName="hestiaProduceId"
                formik={formik}
                defaultValue={formik.values.hestiaProduceId || ""}
              />
            )}
            {formik.values.farmDataCalculator == 0 && (
              <InputItem
                disabled={isCustomEF.current}
                name="Hestia Submission ID"
                description="Please provide the Hestia submission ID that was created for the run."
                type="input"
                onChange={formik.handleChange}
                formikName="hestiaSubmissionId"
                formik={formik}
                defaultValue={formik.values.hestiaSubmissionId || ""}
              />
            )}
            {(formik.values.dataOrigin == 1 ||
              formik.values.dataOrigin == 2) && (
              <>
                <InputItem
                  disabled={isCustomEF.current}
                  name="Calculator Version"
                  description="“1.0 Mondra Release” release in 2019, 1.1 release in 2022 including Hestia Calculator for Farm Impact."
                  type="input"
                  onChange={formik.handleChange}
                  formikName="calculatorVersion"
                  showAsterisk
                  formik={formik}
                  defaultValue={formik.values.calculatorVersion || ""}
                />
                <InputItem
                  disabled={isCustomEF.current}
                  name="PnN Excel Calculator"
                  description=""
                  type="select"
                  defaultValue={formik.values.pnNExcelCalculator}
                  onChange={formik.handleChange}
                  formikName="pnNExcelCalculator"
                  showAsterisk
                  formik={formik}
                  options={sortDropdown(
                    configuration.pnNExcelCalculators,
                    formik.values.pnNExcelCalculator || -1,
                    "description"
                  )}
                />
                <InputItem
                  disabled={isCustomEF.current}
                  name="Mondra MA to fill missing eutrophication"
                  description="Please specify if this MA was carried out to replace a missing eutrophication value in the PnN Original Publication"
                  type="select"
                  defaultValue={formik.values.mondraMAToFillMissingEutrophication}
                  onChange={formik.handleChange}
                  formikName="mondraMAToFillMissingEutrophication"
                  create={factorId == "new"}
                  formik={formik}
                  options={sortDropdown(
                    [
                      { id: true, label: "Yes" },
                      { id: false, label: "No" },
                    ],
                    formik.values.mondraMAToFillMissingEutrophication as boolean,
                    "label"
                  )}
                />
                <InputItem
                  disabled={isCustomEF.current}
                  name="Click up ID"
                  description=""
                  type="input"
                  onChange={formik.handleChange}
                  formikName="clickUpId"
                  formik={formik}
                  defaultValue={formik.values.clickUpId || ""}
                />
                {formik.values.clickUpId && 
                  <div className="flex items-end w-2/4">
                    <a 
                      href={formik.values.clickUpId || ""} 
                      target="_blank" 
                      rel="noopener noreferrer">
                      <Button variant="secondary" disabled={isCustomEF.current}>
                        View clickup
                      </Button>
                    </a>
                    <Button variant="secondary" className="ml-2" onClick={clickHandler} disabled={isCustomEF.current}>Copy URL</Button>
                  </div>
                }
              </>
            )}
          </div>
          <h5 className={"mt-5"}>Impacts</h5>
          <p>
            Please provide environmental impacts for 1 kg of the functional unit
            specified
          </p>
          <div className="piece-content-inputs mb-5">
            <InputItem
              disabled={isCustomEF.current}
              name="Land Use"
              description="Please provide Land use in m2*year."
              type="input"
              addonTitle="m2*y"
              onChange={formik.handleChange}
              formikName="landUse"
              showAsterisk
              formik={formik}
              defaultValue={
                formik.values.landUse === 0 ? "0" : formik.values.landUse
              }
            />
            <InputItem
              disabled={isCustomEF.current}
              name="Biodiversity. sp.yr*10^14"
              description="Please provide the Biodiversity impact in sp.yr*10^14."
              type="input"
              addonTitle="sp.yr*10^14"
              onChange={formik.handleChange}
              formikName="biodiversity"
              formik={formik}
              showAsterisk
              defaultValue={
                formik.values.biodiversity === 0
                  ? "0"
                  : formik.values.biodiversity
              }
            />
            <InputItem
              disabled={isCustomEF.current}
              name="Carbon"
              description="Please provide carbon emissions in g CO2 eq."
              type="input"
              addonTitle="g"
              onChange={formik.handleChange}
              formikName="carbon"
              showAsterisk
              formik={formik}
              defaultValue={
                formik.values.carbon === 0 ? "0" : formik.values.carbon
              }
            />
            <InputItem
              disabled={isCustomEF.current}
              name="Eutrophication"
              description="Please provide eutrophication in g PO43- eq"
              type="input"
              addonTitle="g"
              onChange={formik.handleChange}
              formikName="eutrophication"
              formik={formik}
              showAsterisk
              defaultValue={
                formik.values.eutrophication === 0
                  ? "0"
                  : formik.values.eutrophication
              }
            />
            <InputItem
              disabled={isCustomEF.current}
              name="Freshwater Withdrawal"
              description="Please provide water usage in L"
              type="input"
              addonTitle="L"
              onChange={formik.handleChange}
              formikName="waterUsage"
              formik={formik}
              showAsterisk
              defaultValue={
                formik.values.waterUsage === 0 ? "0" : formik.values.waterUsage
              }
            />
            <InputItem
              disabled={isCustomEF.current}
              name="Freshwater accounting for Water Scarcity"
              description="Please provide water usage including water scarcity conversion in L eq."
              type="input"
              addonTitle="L eq"
              onChange={formik.handleChange}
              formikName="waterUsageIncludingWasterScarcity"
              formik={formik}
              showAsterisk
              defaultValue={
                formik.values.waterUsageIncludingWasterScarcity === 0
                  ? "0"
                  : formik.values.waterUsageIncludingWasterScarcity
              }
            />
          </div>
          <InputItem
            disabled={isCustomEF.current}
            name="Comment"
            description="Please provide any additional information you have on this entry."
            type="text"
            onChange={formik.handleChange}
            formik={formik}
            className={"w-full"}
            defaultValue={formik.values.comment || ""}
            formikName="comment"
          />
          {(formik.values.functionalUnit == 0 ||
            formik.values.functionalUnit == 5 ||
            formik.values.functionalUnit == 6) && (
            <>
              <h5 className={"mt-5"}>Post-Harvest Losses</h5>
              <div className="piece-content-inputs">
                <InputItem
                  disabled={isCustomEF.current}
                  name="Post Harvest Losses ID"
                  description="Please select the produce Post Harvest Losses ID from FAOSTAT (2017) PnN that was used for this entry."
                  type="select"
                  onChange={formik.handleChange}
                  formikName="postHarvestLossesId"
                  showAsterisk
                  formik={formik}
                  defaultValue={
                    formik.values.postHarvestLossesId !== null &&
                    formik.values.postHarvestLossesId >= 0
                      ? formik.values.postHarvestLossesId
                      : -1
                  }
                  options={sortDropdown(
                    configuration.postHarvestLossesIds,
                    formik.values.postHarvestLossesId ?? -1,
                    "description"
                  )}
                />
                <InputItem
                  disabled={isCustomEF.current}
                  name="Post Harvest Losses"
                  description="Please indicate the Post Harvest losses in %."
                  type="input"
                  onChange={formik.handleChange}
                  formikName="postHarvestLosses"
                  addonTitle="%"
                  formik={formik}
                  showAsterisk
                  addLocal={(params) =>
                    addLocalPopUpNote({
                      formik,
                      index: formik.values.postHarvestLossesNotes.length,
                      ...params,
                    })
                  }
                  notes={formik.values.postHarvestLossesNotes || []}
                  defaultValue={formik.values.postHarvestLosses === null
                    ? ""
                    : formik.values.postHarvestLosses.toString()}
                />
              </div>
            </>
          )}
          <h5 className="mt-5 mb-5">Data Quality</h5>
          {formik.values.dataOrigin == 1 && (
            <>
              <h6>Data quality: Impact Proxy</h6>
              <div className="piece-content-inputs">
                <div className="inputItem">
                  <h3 className="mt-5">Missing Impact Factor</h3>
                  <p className={"decoration-gray-600 mb-3"}>
                    Please specify if this MA was carried out to replace a
                    missing eutrophication value in the PnN Original
                    Publication.
                  </p>
                  <Select                    
                    isDisabled={isCustomEF.current}
                    isClearable={false}
                    defaultValue={[
                      ...getDefaults(
                        factor.missingImpactFactor,
                        configuration.missingImpactFactors
                      ),
                    ]}
                    name="missingImpactFactor"
                    onChange={(newValue: any, action: any) => {
                      action.action === "remove-value" &&
                        formik.setFieldValue(
                          "missingImpactFactor",
                          formik.values.missingImpactFactor !== null
                            ? (formik.values.missingImpactFactor -=
                                action.removedValue.value)
                            : null
                        );
                      action.action === "select-option" &&
                        formik.setFieldValue(
                          "missingImpactFactor",
                          (formik.values.missingImpactFactor +=
                            action.option.value)
                        );
                    }}
                    isMulti
                    className="basic-multi-select"
                    options={multipleObject(configuration.missingImpactFactors)}
                  />
                  {formik.touched.missingImpactFactor &&
                  formik.errors.missingImpactFactor ? (
                    <span className="formik-error">
                      {formik.errors.missingImpactFactor}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
                <InputItem
                  disabled={isCustomEF.current}
                  name="Proxy Country"
                  description=""
                  type="select"
                  defaultValue={formik.values.proxyCountryId || 0}
                  onChange={formik.handleChange}
                  formikName="proxyCountryId"
                  create={true}
                  formik={formik}
                  options={sortDropdown(
                    configuration.countries,
                    formik.values.proxyCountryId !== null
                      ? +formik.values.proxyCountryId
                      : 0,
                    "countryName"
                  )}
                />
                {isNew ||
                (!isNew && factor.proxyProduceId === null) ? (
                  <>
                    <InputItem
                      disabled={isCustomEF.current}
                      name="Proxy Class"
                      description=""
                      type="select"
                      defaultValue={selectedProxyProduce.class}
                      onChange={(e) => {
                        const classSelected = (e.target as any).value
                        if(classSelected){
                          getProcessedDropdowns(
                            `category?classId=${classSelected}`
                          ).then((res) => setProxyCategory(res.data));
                        }
                        setSelectedProxyProduce({...defaultProxyProduceDetails, class: +classSelected})
                        formik.setFieldValue('proxyProduceId', -1)
                      }}
                      options={sortDropdown(
                        configuration.eutrophicationProxyProduceTaxonomies
                          .classes,
                        1,
                        "name"
                      )}
                    />
                    <InputItem
                      name="Proxy Category"
                      description=""
                      type="select"
                      defaultValue={selectedProxyProduce.category}
                      disabled={proxyCategory.length === 0 || isCustomEF.current}
                      onChange={(e) => {
                        const categoryProxySelected = (e.target as any).value
                        if(categoryProxySelected){
                          getProcessedDropdowns(
                            `subcategory?categoryId=${categoryProxySelected}`
                          ).then((res) => setProxySubcategory(res.data));
                        }
                        setSelectedProxyProduce(prevProxy => ({...prevProxy, productGroup: -1, category: +categoryProxySelected}))
                        formik.setFieldValue('proxyProduceId', -1)
                      }}
                      options={proxyCategory.map((el) => ({
                        label: el.name,
                        value: el.id,
                      }))}
                    />
                    <InputItem
                      name="Proxy Product Group"
                      description=""
                      type="select"
                      disabled={proxySubcategory.length === 0 || isCustomEF.current}
                      defaultValue={selectedProxyProduce.productGroup}
                      onChange={(e) => {
                        const productGroupProxySelected = (e.target as any).value
                        if(productGroupProxySelected){
                          getProcessedDropdowns(
                            `functionalUnit?subcategoryId=${productGroupProxySelected}`
                          ).then((res) => setProxyFunctionalUnit(res.data));
                        }
                        setSelectedProxyProduce(prevProxy => ({...prevProxy, productGroup: +productGroupProxySelected}))
                        formik.setFieldValue('proxyProduceId', -1)
                      }}
                      options={proxySubcategory.map((el) => ({
                        label: el.name,
                        value: el.id,
                      }))}
                    />
                    <InputItem
                      name="Proxy Produce"
                      description="Please select a suitable product Name from the Ingredient taxonomy."
                      type="select"
                      defaultValue={formik.values.proxyProduceId}                   
                      disabled={proxyFunctionalUnit.length === 0 || isCustomEF.current}
                      onChange={formik.handleChange}
                      formikName="proxyProduceId"
                      options={proxyFunctionalUnit.map((el) => ({
                        label: el.name,
                        value: el.id,
                      }))}
                    />
                  </>
                ) : (
                  configuration.eutrophicationProxyProduceTaxonomies
                    .functionalUnits !== null && (
                    <>
                      <InputItem
                        disabled={isCustomEF.current}
                        name="Proxy Class"
                        description=""
                        type="select"
                        defaultValue={selectedProxyProduce.class}
                        onChange={(e) => {
                          const classSelected = (e.target as any).value
                          if(classSelected){
                            getProcessedDropdowns(
                              `category?classId=${classSelected}`
                            ).then((res) => setProxyCategory(res.data));
                          }
                          setSelectedProxyProduce({...defaultProxyProduceDetails, class: +classSelected})
                          formik.setFieldValue('proxyProduceId', -1)
                        }}
                        options={configuration.eutrophicationProxyProduceTaxonomies.classes.map(
                          (el) => ({
                            label: el.name,
                            value: el.id,
                          })
                        )}
                      />
                      <InputItem
                        name="Proxy Category"
                        description=""
                        type="select"
                        defaultValue={selectedProxyProduce.category}
                        onChange={(e) => {
                          const categoryProxySelected = (e.target as any).value
                          if(categoryProxySelected){
                            getProcessedDropdowns(
                              `subcategory?categoryId=${categoryProxySelected}`
                            ).then((res) => setProxySubcategory(res.data));
                          }
                          setSelectedProxyProduce(prevProxy => ({...prevProxy, productGroup: -1, category: +categoryProxySelected}))
                          formik.setFieldValue('proxyProduceId', -1)
                        }}
                        disabled={
                          (formik.values.proxyProduceId === null &&
                          proxyCategory.length === 0 )
                          || isCustomEF.current
                        }
                        options={
                          formik.values.proxyProduceId === null
                            ? proxyCategory.map((el) => ({
                                label: el.name,
                                value: el.id,
                              }))
                            : proxyCategory.length !== 0
                            ? proxyCategory.map((el) => ({
                                label: el.name,
                                value: el.id,
                              }))
                            : sortDropdown(
                                configuration
                                  .eutrophicationProxyProduceTaxonomies
                                  .categories,
                                configuration.eutrophicationProxyProduceTaxonomies.categories.filter(
                                  (el) =>
                                    el.id ===
                                    configuration.eutrophicationProxyProduceTaxonomies.subcategories.filter(
                                      (el) =>
                                        el.id ===
                                        configuration.eutrophicationProxyProduceTaxonomies.functionalUnits.find(
                                          (el) =>
                                            el.id === factor.proxyProduceId
                                        )?.subcategoryId
                                    )[0]?.categoryId
                                )[0]?.id,
                                "name"
                              )
                        }
                      />
                      <InputItem
                        name="Proxy Product Group"
                        description=""
                        type="select"
                        defaultValue={selectedProxyProduce.productGroup}
                        onChange={(e) => {
                          const productGroupProxySelected = (e.target as any).value
                          if(productGroupProxySelected){
                            getProcessedDropdowns(
                              `functionalUnit?subcategoryId=${productGroupProxySelected}`
                            ).then((res) => setProxyFunctionalUnit(res.data));
                          }
                          setSelectedProxyProduce(prevProxy => ({...prevProxy, productGroup: +productGroupProxySelected}))
                          formik.setFieldValue('proxyProduceId', -1)
                        }}
                        disabled={
                          (formik.values.proxyProduceId === null &&
                          proxySubcategory.length === 0) 
                          || isCustomEF.current
                        }
                        options={
                          formik.values.proxyProduceId === null
                            ? proxySubcategory.map((el) => ({
                                label: el.name,
                                value: el.id,
                              }))
                            : proxySubcategory.length !== 0
                            ? proxySubcategory.map((el) => ({
                                label: el.name,
                                value: el.id,
                              }))
                            : sortDropdown(
                                configuration
                                  .eutrophicationProxyProduceTaxonomies
                                  .subcategories,
                                configuration.eutrophicationProxyProduceTaxonomies.subcategories.filter(
                                  (el) =>
                                    el.id ===
                                    configuration.eutrophicationProxyProduceTaxonomies.functionalUnits.find(
                                      (el) => el.id === factor.proxyProduceId
                                    )?.subcategoryId
                                )[0]?.id,
                                "name"
                              )
                        }
                      />
                      <InputItem
                        name="Proxy Produce"
                        description="Please select a suitable product Name from the Ingredient taxonomy."
                        type="select"
                        defaultValue={formik.values.proxyProduceId}
                        onChange={formik.handleChange}
                        formikName="proxyProduceId"
                        disabled={
                          (formik.values.proxyProduceId === null &&
                          proxyFunctionalUnit.length === 0 )
                          || isCustomEF.current
                        }
                        create={
                          proxySubcategory.length !== 0 ||
                          proxyFunctionalUnit.length !== 0
                        }
                        options={
                          formik.values.proxyProduceId === null
                            ? proxyFunctionalUnit.map((el) => ({
                                label: el.name,
                                value: el.id,
                              }))
                            : proxyFunctionalUnit.length !== 0 ||
                              proxySubcategory.length !== 0
                            ? proxyFunctionalUnit.map((el) => ({
                                label: el.name,
                                value: el.id,
                              }))
                            : sortDropdown(
                                configuration
                                  .eutrophicationProxyProduceTaxonomies
                                  .functionalUnits,
                                formik.values.proxyProduceId || 0,
                                "name"
                              )
                        }
                      />
                    </>
                  )
                )}
              </div>
            </>
          )}
          <h6 className="mt-5">Data Quality: Reliability and Completeness</h6>
          <div className="piece-content-inputs mb-5">
            <InputItem
              disabled={isCustomEF.current}
              name="Number of studies"
              description=""
              type="input"
              onChange={formik.handleChange}
              formikName="numberOfStudies"
              formik={formik}
              showAsterisk
              defaultValue={formik.values.numberOfStudies || ""}
            />
            <InputItem
              disabled={isCustomEF.current}
              name="Reference Study"
              description="Please list the references that were used to create this record."
              type="input"
              onChange={formik.handleChange}
              formikName="referenceStudy"
              formik={formik}
              defaultValue={formik.values.referenceStudy || ""}
            />
            <InputItem
              disabled={isCustomEF.current}
              name="Secondary Data Reference Source Type"
              description="If multiple references sources where used to generate this record please indicate the overall reference source type by select the reference where the majority of the data was retrieved from, if this is not possible please select the source type with the lowest ranking. If this is not possible take the reference which was published dating back the longest. Please note “Peer-reviewed published literature” can be a journal, conference publication of book chapter."
              type="select"
              defaultValue={formik.values.secondaryDataReferenceSourceType}
              onChange={formik.handleChange}
              formik={formik}
              formikName="secondaryDataReferenceSourceType"
              showAsterisk
              options={sortDropdown(
                configuration.secondaryDataReferenceSourceType,
                formik.values.secondaryDataReferenceSourceType,
                "description"
              )}
            />
            <InputItem
              disabled
              name="Data quality reliability score"
              description="Read only"
              type="input"
              formik={formik}
              defaultValue={formik.values.dataQualityReliabilityScore || ""}
              formikName="dataQualityReliabilityScore"
            />
            <InputItem
              disabled={isCustomEF.current}
              name="Reference Data Publication Year"
              description="The year in which most of the data was published. If the data was equally collected from multiple studies please select the publication year from the study published the furthest in the past. "
              type="input"
              onChange={formik.handleChange}
              formik={formik}
              defaultValue={formik.values.referenceDataPublicationYear || ""}
              formikName="referenceDataPublicationYear"
              showAsterisk
            />
            <InputItem
              disabled={isCustomEF.current}
              name="References Sampling Year"
              description="The year in which most of the data was collected. If this is a time frame please choose the end date. If the data was equally collected from multiple studies please select the sourcing year from the study published the furthest in the past."
              type="input"
              onChange={formik.handleChange}
              formik={formik}
              defaultValue={formik.values.referencesSamplingYear || ""}
              formikName="referencesSamplingYear"
            />
            <InputItem
              disabled={isCustomEF.current}
              name="Reference Technology Proxy Contribution"
              description="Please indicate, the sum of the respective percentage contribution for the five research areas that use a proxy reference technology type (e.g. type of produce) to complete the dataset: Fertilisers – 15.3%, Pesticides – 15.3%, Irrigation  – 15.3%, Machinery, fuel/energy 15.3%, Yield, secondary products (Y/N), type of products & economic allocation – 38.5%. Please indicate the value as a percentage (e.g. 15.3%)."
              type="input"
              formik={formik}
              addonTitle="%"
              onChange={formik.handleChange}
              defaultValue={
                formik.values.referenceTechnologyProxyContribution === null
                  ? ""
                  : formik.values.referenceTechnologyProxyContribution.toString()
              }
              formikName="referenceTechnologyProxyContribution"
            />
          </div>
          <h3 className="mt-5">Reference Technology Type</h3>
          <p className={"decoration-gray-600 mb-1"}>
            Please specify the technology type (e.g. produce type for farming)
            that was used to generate this entry. This should be the produce
            that was used for the majority of the raw data entry. Please
            indicate the respective technology, if a proxy
            (technological/produce and geographical) was used for more than 20%
            of the data (excluding any gap filling applied in the calculator) –
            Please indicate, if a proxy was used for more than 20% of the
            weighting towards the key five research areas:
            <br />
            - Fertilisers – 15.3%
            <br />
            - Pesticides – 15.3%
            <br />
            - Irrigation, quantity of irrigation water – 15.3%
            <br />
            - machinery, fuel/energy inputs – 15.3%
            <br />- yield, secondary products (Y/N), type of products & economic
            allocation – 38.5%
          </p>
          <InputItem
            disabled={isCustomEF.current}
            name=""
            description=""
            type="text"
            onChange={formik.handleChange}
            formik={formik}
            showAsterisk
            className={"w-full mt-2"}
            defaultValue={formik.values.referenceTechnologyType || ""}
            formikName="referenceTechnologyType"
          />
          <div className="piece-content-inputs mb-5">
            <InputItem
              disabled={isCustomEF.current}
              name="Reference Sourcing Country"
              description="Please select the Country Name identifier in which the data was collected (not published). Please select the country where most of the data was collected from. Only use publication country if no other information is available."
              type="select"
              onChange={formik.handleChange}
              defaultValue={
                formik.values.referenceSourcingCountry
                  ? formik.values.referenceSourcingCountry
                  : -1
              }
              formik={formik}
              formikName="referenceSourcingCountry"
              options={sortDropdown(
                configuration.countries,
                formik.values.referenceSourcingCountry ?? -1,
                "countryName"
              )}
            />
            <InputItem
              disabled={isCustomEF.current}
              name="Reference Country ISO Code"
              description="Please select the Country Name identifier in which the data was collected (not published). Please select the country where most of the data was collected from. Only use publication country if no other information is available."
              type="select"
              onChange={formik.handleChange}
              defaultValue={
                formik.values.referenceSourcingCountry
                  ? formik.values.referenceSourcingCountry
                  : -1
              }
              formik={formik}
              formikName="referenceSourcingCountry"
              options={sortDropdown(
                configuration.countries,
                formik.values.referenceSourcingCountry ?? -1,
                "countryISO"
              )}
            />
            <InputItem
              disabled={isCustomEF.current}
              name="Reference Region Identifier"
              description="The region identifier in which the data was collected. Please select the region where most of the data was collected from."
              type="select"
              defaultValue={formik.values.referenceRegionIdentifier}
              onChange={formik.handleChange}
              formik={formik}
              formikName="referenceRegionIdentifier"
              showAsterisk
              options={sortDropdown(
                configuration.regions,
                formik.values.referenceRegionIdentifier,
                "name"
              )}
            />
            <InputItem
              disabled={isCustomEF.current}
              name="Reference Sourcing Country Proxy Contribution"
              description="Please indicate, the sum of the respective percentage contribution for the five research areas that use a proxy country to complete the dataset:  Fertilisers – 15.3%, Pesticides – 15.3%, Irrigation  – 15.3%, Machinery, fuel/energy 15.3%, Yield, secondary products (Y/N), type of products & economic allocation – 38.5%. Please indicate the value as a percentage (e.g. 15.3%)."
              type="input"
              formik={formik}
              addonTitle="%"
              onChange={formik.handleChange}
              defaultValue={
                formik.values.referenceSourcingCountryProxyContribution === null
                  ? ""
                  : formik.values.referenceSourcingCountryProxyContribution.toString()
              }
              formikName="referenceSourcingCountryProxyContribution"
            />
          </div>
          <InputItem
            disabled={isCustomEF.current}
            name="Data Quality Comment"
            description="Please provide any comments on assumption that were made to
              determine the criteria for data quality for this record taking
              multiple references into account."
            type="text"
            onChange={formik.handleChange}
            formik={formik}
            className={"w-full"}
            defaultValue={formik.values.dataQualityComment || ""}
            formikName="dataQualityComment"
          />
          {errors &&
            (Array.isArray(errors) ? (
              <ul className="piece-errors">
                {errors.map(([key, value]) =>
                  value.map((err) => (
                    <li>
                      <b>{key}:</b> {err}
                    </li>
                  ))
                )}
              </ul>
            ) : (
              <ul className="piece-errors">
                <li>{errors}</li>
              </ul>
            ))}
          <div className="piece-actions">
            {sessionStorage.getItem("permission") == "true" &&
              factorId != "new" && (
                <>
                  <button
                    type="submit"
                    className="piece-content-publish"
                    disabled={
                      formik.isSubmitting || isCustomEF.current ||
                      JSON.stringify(factor) != JSON.stringify(formik.values)
                    }
                  >
                    {factor.state === 0 ? "Publish" : "Unpublish"}
                  </button>
                </>
              )}
            {JSON.stringify(factor) !== JSON.stringify(formik.values) && (
              <div
                className="bg-orange-100 border-l-4 border-orange-500 px-4 py-2 rounded text-color-rose-500"
                role="alert"
              >
                <p>Please save before publishing new changes</p>
              </div>
            )}
            <button
              className="piece-content-submit bg-primary-600"
              onClick={saveDraft}
              disabled={formik.isSubmitting}
            >
              Save
            </button>
          </div>
        </form>
      </div>
      <ActivityRightPanel
        activityId={factorId}
        activityType={ActivityTypes.EmissionFactors}
        auditCreatedBy={factor.researcherName}
        auditLastUpdatedBy={factor.lastUpdatedName}
        companyId={formik.values.companyId}
        isVerified={formik.values.isVerified}
        verificationComments={formik.values.verificationComments}
        verifiedByName={formik.values.verifiedByName}
        verifiedOn={formik.values.verifiedOn}
        onChange={formik.handleChange}
      />
    </>
  ) : (
    <div className="overlay">
      <LottieLoader
        lottieType="butterflyLottie"
        style={{ height: "10.25rem", width: "10.25rem" }}
      />
    </div>
  );
};

export default Factor;
