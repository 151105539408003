import { FC, useState } from "react";
import { FormDialog, Button } from "@mondra/ui-components";
import InputItem from "../../../../components/InputItem";
import { useFormik } from "formik";
import { emissionToAirSchema } from "../utils";
import { addLocalPopUpNote } from "../../../../shared/noteFunctions";

interface ComponentFormProps {
  resourceSources: any;
  addProcess: (variables?: any, array?: string) => void;
}

const EmissionToAirForm: FC<ComponentFormProps> = ({ resourceSources, addProcess }) => {
  const [openForm, setOpenForm] = useState(false);

  const initialValues = {
    resourceSourceId: 0,
    quantity: '',
    quantityNotes: [],
  };

  const formik = useFormik({
    initialValues,
    validationSchema: emissionToAirSchema,
    onSubmit: (values) => {
      values.resourceSourceId = +values.resourceSourceId;
      addProcess(values, "emissions");
      setTimeout(() => setOpenForm(false), 500);
    },
  });

  const onCreateComponent = () => {
    setOpenForm(true);
    formik.setValues(initialValues);
    formik.setTouched({}, false);
  };

  return (
    <>
      <Button
        title="Create component"
        iconType="add"
        variant="primary"
        className={"mt-5"}
        onClick={onCreateComponent}
      >
        Add Emission
      </Button>
      <FormDialog
        title="Emissions"
        formId="component"
        onClose={() => setTimeout(() => setOpenForm(false), 500)}
        primaryBtnText="Create"
        onPrimaryButtonClick={formik.handleSubmit}
        open={openForm}
      >
        <form onSubmit={formik.handleSubmit}>
          <div className="piece-content-inputs">
            <InputItem
              name="Emission type"
              description="Please specify what kind of emission to air was generated during the process. For evaporation losses please consider the density of steam at 1 bar (0.59 kg/m3)"
              type="select"
              create={true}
              formik={formik}
              showAsterisk
              onChange={formik.handleChange}
              formikName="resourceSourceId"
              defaultValue={
                formik.values.resourceSourceId
                  ? formik.values.resourceSourceId
                  : -1
              }
              options={resourceSources.map((el) => ({
                label: el.combinedName,
                value: el.id,
              }))}
            />
            <InputItem
              name="Emission ID"
              description=""
              type="select"
              create={true}
              formik={formik}
              showAsterisk
              onChange={formik.handleChange}
              formikName="resourceSourceId"
              defaultValue={
                formik.values.resourceSourceId
                  ? formik.values.resourceSourceId
                  : -1
              }
              options={resourceSources.map((el) => ({
                label: el.combinedId,
                value: el.id,
              }))}
            />
            <InputItem
              name="Quantity"
              description="Please provide the quantity of the emission in the displayed unit"
              type="input"
              formik={formik}
              onChange={formik.handleChange}
              defaultValue={formik.values.quantity}
              formikName="quantity"
              showAsterisk
              addonTitle={
                resourceSources.find(
                  (el) => el.id === +formik.values.resourceSourceId
                )?.unit
              }
              addLocal={(params) => 
                addLocalPopUpNote({ 
                  formik,
                  index: formik.values.quantityNotes.length,
                  ...params
                })
              }
              notes={formik.values.quantityNotes || []}
            />
          </div>
        </form>
      </FormDialog>
    </>
  );
};

export default EmissionToAirForm;
