import { FC, useEffect, useState, EffectCallback } from "react";
import { useAppDispatch } from "../../../hooks/redux";
import { useSearchParams, useNavigate } from "react-router-dom";
import {
  Icon,
  Input,
  LottieLoader,
  Notification,
  Button,
  Textarea,
  Breadcrumbs,
} from "@mondra/ui-components";
import { useAppSelector } from "../../../hooks/redux";
import { useFormik } from "formik";
import { fetchStorage, getConfiguration } from "./storageActions";
import { getAudit } from "../../../redux/action-creators/auditActions";
import { sortDropdown, transformFormValues } from "../../../shared/formFunctions";
import {
  updateStorageActivity,
  publishStorageActivity,
  unPublishStorageActivity,
  createStorageActivity,
} from "./api";

import { usePrompt } from "../../../hooks/usePrompt";

import InputItem from "../../../components/InputItem";
import { formSchema } from "./utils";
import Collapsible from "react-collapsible";
import { IResourcesSources } from "./types";
import ComponentForm from "./components/ComponentForm";
import {
  setDefaultStorage,
  handlePublishStatus,
  addNote,
} from "../../../redux/storageSlice";
import { addLocalFormNote } from "../../../shared/noteFunctions";
import { showCorrelationIdError } from "../../../redux/correlationIdSlice";
import "./index.scss";
import ActivityRightPanel from "../../../components/ActivityRightPanel";
import { ActivityTypes } from "../../../const";

const Storage: FC = ({}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [showError, setShowError] = useState<string>("");
  const [errors, setErrors] = useState<[any, string[]][]>();
  const [showPublished, setShowPublished] = useState<boolean>(false);
  const { loading, storage, configuration } = useAppSelector(
    (state) => state.storageSlice
  );
  const { loading: companiesLoading } = useAppSelector(
    (state) => state.companiesSlice
  );
  let storageId = searchParams.get("itemId") || "";
  const isNew = storageId === "new"

  useEffect((): ReturnType<EffectCallback> => {
    if(!companiesLoading){
      if (storageId == "") {
        navigate("/not-found");
      }
      dispatch(getConfiguration());
      if (!isNew) {
        dispatch(fetchStorage(storageId, () => navigate("/storage-activity")));
      }
      return () => {
        dispatch(setDefaultStorage());
      };
    }
  }, [companiesLoading, isNew]);

  useEffect(() => {
    formik.setValues({ ...storage });
  }, [storage]);

  const formik = useFormik({
    initialValues: {
      ...storage,
    },
    validationSchema: formSchema,
    onSubmit: async () => {
      if (storage.state === 0) {
        publishStorageActivity(storageId)
          .then(() => {
            dispatch(handlePublishStatus(1));
            setShowPublished(true);
            setTimeout(() => {
              setShowPublished(false);
            }, 4000);
          })
          .catch((error) =>
            setShowError(error.response.data.title)
          );
      } else {
        unPublishStorageActivity(storageId)
          .then(() => {
            dispatch(handlePublishStatus(0));
            setShowPublished(true);
            setTimeout(() => {
              setShowPublished(false);
            }, 4000);
          })
          .catch((error) =>
            setShowError(error.response.data.title)
          );
      }
    },
  });

  const saveDraft = async (e) => {
    e.preventDefault();
    const {values} = formik;

    const tValues = transformFormValues(values)

    if (storageId === "new") {
      try {
        await createStorageActivity(tValues).then(() => {
          navigate("/storage-activity");
        });
      } catch (error: any) {
        if (error.showCorrelationId) {
          dispatch(showCorrelationIdError(error.CorrelationId));
        } else {
          setShowError(
            typeof error.response.data === "string" 
              ? error.response.data
              : typeof error.response.data.errors === "string"
              ? error.response.data.errors
              : Object.values(error.response.data.errors).toString().replace(/!,/g, '!\n')
          );
          setTimeout(() => {
            setShowError("");
          }, 5000);
          setErrors(
            typeof error.response.data.errors === "string" 
              ? error.response.data.errors
              : Object.entries(error.response.data.errors)
          );
        }
      }
    } else {
      try {
        await updateStorageActivity(storageId, tValues)
          .then(() =>
            dispatch(
              fetchStorage(storageId, () => navigate("/storage-activity"))
            )
          )
          .then(() => {
            setShowNotification(true);
            setTimeout(() => {
              setShowNotification(false);
            }, 5000);
          });
      } catch (error: any) {
        if (error.showCorrelationId) {
          dispatch(showCorrelationIdError(error.CorrelationId));
        } else {
          setShowError(
            typeof error.response.data === "string" 
              ? error.response.data
              : typeof error.response.data.errors === "string"
              ? error.response.data.errors
              : Object.values(error.response.data.errors).toString().replace(/!,/g, '!\n')
          );
          setTimeout(() => {
            setShowError("");
          }, 5000);
          setErrors(
            typeof error.response.data.errors === "string" 
              ? error.response.data.errors
              : Object.entries(error.response.data.errors)
          );
        }
      }
    }
  };


  usePrompt(
    "There are unsaved changes, do you want to leave?",
    !isNew
      ? JSON.stringify(storage) != JSON.stringify(formik.values)
      : false
  );

  const addComponent = (values) => {
    formik.setValues({
      ...formik.values,
      resourceSources: [...formik.values.resourceSources, values],
    });
  };

  const deleteComponent = (idx) => {
    const newComponents = formik.values.resourceSources.filter(
      (el, index) => index != +idx
    );
    formik.setValues({
      ...formik.values,
      resourceSources: newComponents,
    });
  };


  return !loading || isNew ? (
    <>
      <div className="piece content">
        {showError !== "" && (
          <div className={"piece-notification"}>
            <Notification type="error" label="Oops:" description={showError} />
          </div>
        )}
        {showPublished && (
          <div className={"piece-notification"}>
            <Notification
              type="success"
              label="Success:"
              description="Status was updated!"
              isToast={false}
            />
          </div>
        )}
        {showNotification && (
          <div className={"piece-notification"}>
            <Notification
              type="success"
              label="Success:"
              description="Item was updated!"
              isToast={false}
            />
          </div>
        )}
        <div className="mt-3 mb-3">
          <Breadcrumbs
            routes={[
              {
                path: "/storage-activity",
                title: "Storage Activity Table",
              },
              {
                path: `/storage-activity/storage?itemId=${storageId}`,
                title: "Storage Activity Item",
              },
            ]}
          />
        </div>
        {storageId !== "new" && (
          <div className="piece-header">
            <div
              className={`piece-header-status ${
                storage.state === 0 ? "bg-red-500" : "bg-emerald-500"
              }`}
            >
              {storage.state === 0 ? "Not Published" : "Published"}
            </div>
            {storage?.lastPublishedDate ? (
              <span className={"inputItem-header"}>
                {`Last published date: ${new Date(
                  storage.lastPublishedDate
                ).toLocaleString()}
                `}
              </span>
            ) : (
              ""
            )}
          </div>
        )}
        <p className={"decoration-gray-600 mt-1 mb-7 piece-description"}>
          This database is a collation of resource type and consumption involved
          in storing of different food products under different conditions. All
          storage activity data has been reported for a functional unit of 1 kg
          of the stored product.
        </p>

        <form onSubmit={formik.handleSubmit} className="piece-content">
          <h5 className={"mt-5 mb-5"}>Activity Type</h5>
          <h3>Activity Name</h3>
          <p className={"decoration-gray-600 mb-1"}>
            A combination of the [Activity Conditions] – [Activity Enclosure] –
            [Food category, data applies to] – [Activity Country Geography].
            Generated automatically.
          </p>
          <Input
            disabled={true}
            className={"w-full h-20 mt-2 mb-5"}
            value={`${
              (configuration.temperatureConditions[
                formik.values.temperatureConditions
              ]?.description &&
                configuration.temperatureConditions[
                  formik.values.temperatureConditions
                ]?.description + "-") ||
              ""
            }${
              (configuration.enclosure[formik.values.enclosure]?.description &&
                configuration.enclosure[formik.values.enclosure]?.description +
                  "-") ||
              ""
            }${
              (configuration.foodCategory[formik.values.foodCategory - 1]
                ?.description &&
                configuration.foodCategory[formik.values.foodCategory - 1]
                  ?.description + "-") ||
              ""
            }${
              (configuration.countries.find(
                (el) => el.id == formik.values.referenceSourcingCountry
              )?.countryISO &&
                configuration.countries.find(
                  (el) => el.id == formik.values.referenceSourcingCountry
                )?.countryISO + "-") ||
              ""
            }${
              configuration.regions.find(
                (el) => el.id == formik.values.referenceRegionIdentifier
              )?.name || ""
            }`}
          />
          <h3>Activity Description <span className="text-red-500">*</span></h3>
          <p className={"decoration-gray-600 mb-1"}>
            please provide a description of the storage activity e.g. Chilled
            temperature conditions range from -1 to 12 C. Average refrigerant
            charge in cold storage is 65 kg/yr with a leakage rate of 8%. R-134A
            is the most commonly used refrigerant.
          </p>
          <Textarea
            className={"w-full h-20 mt-2 mb-5"}
            onChange={formik.handleChange}
            value={formik.values.description || ""}
            name="description"
          />
          {formik.touched.description && formik.errors.description ? (
            <span className="formik-error">{formik.errors.description}</span>
          ) : (
            ""
          )}
          <div className="piece-content-inputs">
            <InputItem
              name="Activity Conditions"
              description="Select an activity conditions"
              type="select"
              defaultValue={formik.values.temperatureConditions}
              onChange={formik.handleChange}
              formikName="temperatureConditions"
              create={isNew}
              formik={formik}
              showAsterisk
              options={sortDropdown(
                configuration.temperatureConditions,
                formik.values.temperatureConditions,
                "description"
              )}
            />
            <InputItem
              name="Activity Enclosure"
              description="Select an activity enclosure"
              type="select"
              defaultValue={formik.values.enclosure}
              onChange={formik.handleChange}
              formikName="enclosure"
              formik={formik}
              showAsterisk
              options={sortDropdown(
                configuration.enclosure,
                formik.values.enclosure,
                "description"
              )}
            />
            <InputItem
              name="Food category"
              description="Choose a food category"
              type="select"
              formik={formik}
              showAsterisk
              defaultValue={formik.values.foodCategory}
              onChange={formik.handleChange}
              formikName="foodCategory"
              options={sortDropdown(
                configuration.foodCategory,
                formik.values.foodCategory,
                "description"
              )}
            />
            <InputItem
              name="External code"
              description=""
              type="input"
              disabled
              formik={formik}
              onChange={formik.handleChange}
              defaultValue={formik.values.code || ""}
              formikName="code"
            />
          </div>
          <div className={"piece-content-collapsible"}>
            <h5 className={"mt-5"}>Resource Sources</h5>
            {formik.values.resourceSources.length === 0 && (
              <span className="text-rose-600">
                You should have at least one component
              </span>
            )}
            <br/>
            {formik.values.resourceSources &&
              formik.values.resourceSources.map(
                (component: IResourcesSources, index: number) => {
                  return (
                    <Collapsible
                      key={index + component.id}
                      trigger={
                        <div className="collapsible_trigger">
                          <span>
                            {
                              configuration.resourceSources.find(
                                (el) => el.id === +component.resourceSourceId
                              )?.combinedName
                            }
                          </span>
                          <Icon
                            className="collapsible_icon"
                            type="chevronDown"
                          />
                        </div>
                      }
                    >
                      <div className="piece-content-inputs">
                        <InputItem
                          name="Resource & Source Name"
                          description="Select your resource & source name"
                          type="select"
                          formik={formik}
                          onChange={formik.handleChange}
                          defaultValue={
                            formik.values.resourceSources[index].resourceSourceId
                              ? formik.values.resourceSources[index].resourceSourceId
                              : -1
                          }
                          formikName={`resourceSources[${index}].resourceSourceId`}
                          options={sortDropdown(
                            configuration.resourceSources,
                            formik.values.resourceSources[index]
                              .resourceSourceId,
                            "combinedName"
                          )}
                          index={index}
                        />
                        <InputItem
                          name="Resource & Source ID"
                          description="Select your resource & source ID"
                          type="select"
                          formik={formik}
                          onChange={formik.handleChange}
                          defaultValue={
                            formik.values.resourceSources[index].resourceSourceId
                              ? formik.values.resourceSources[index].resourceSourceId
                              : -1
                          }
                          formikName={`resourceSources[${index}].resourceSourceId`}
                          options={sortDropdown(
                            configuration.resourceSources,
                            formik.values.resourceSources[index]
                              .resourceSourceId,
                            "combinedId"
                          )}
                          index={index}
                        />
                        <InputItem
                          name="Quantity Used"
                          description="Enter number of quantities"
                          type="input"
                          formik={formik}
                          onChange={(e) => {
                            formik.setFieldTouched(`resourceSources[${index}].quantity`)
                            formik.handleChange(e)
                           }}
                          defaultValue={component.quantity}
                          addonTitle={
                            configuration.resourceSources.find(
                              (el) => el.id === +formik.values.resourceSources[index].resourceSourceId
                            )?.unit
                          }
                          formikName={`resourceSources[${index}].quantity`}
                          notes={component.quantityNotes || []}
                           addLocal={(params) =>
                            addLocalFormNote({
                              formik,
                              arrayName: "resourceSources",
                              componentIndex: index,
                              index: component.quantityNotes?.length,
                              ...params
                            })
                           }
                          index={index}
                        />
                      </div>
                      <Button
                        className={"piece-component-delete"}
                        onClick={() => deleteComponent(index)}
                        variant="danger"
                        iconType="close"
                      >
                        Delete
                      </Button>
                    </Collapsible>
                  );
                }
              )}
            <ComponentForm
              addComponent={addComponent}
              resourceSources={configuration.resourceSources}
            />
          </div>
          <div className={"piece-content-inputs"}></div>
          <h5 className={"mt-5"}>Data Quality</h5>
          <div className={"piece-content-inputs mb-5"}>
            <InputItem
              name="Secondary Data Reference Source Type"
              description="If multiple references sources where used to generate this record please indicate the overall reference source type by select the reference where the majority of the data was retrieved from, if this is not possible please select the source type with the lowest ranking. If this is not possible take the reference which was published dating back the longest. Please note “Peer-reviewed published literature” can be a journal, conference publication of book chapter."
              type="select"
              defaultValue={formik.values.secondaryDataReferenceSourceType}
              onChange={formik.handleChange}
              formik={formik}
              showAsterisk
              formikName="secondaryDataReferenceSourceType"
              options={sortDropdown(
                configuration.secondaryDataReferenceSourceType,
                formik.values.secondaryDataReferenceSourceType,
                "description"
              )}
            />
            <InputItem
              disabled
              name="Data quality reliability score"
              description="Read only"
              type="input"
              formik={formik}
              defaultValue={formik.values.dataQualityReliabilityScore || ""}
              formikName="dataQualityReliabilityScore"
            />
            <InputItem
              name="References Sampling Year"
              description="The year in which most of the data was collected. If this is a time frame please choose the end date. If the data was equally collected from multiple studies please select the sourcing year from the study published the furthest in the past."
              type="input"
              onChange={formik.handleChange}
              formik={formik}
              defaultValue={formik.values.referencesSamplingYear || ""}
              formikName="referencesSamplingYear"
            />
            <InputItem
              name="Reference Data Publication Year"
              description="The year in which most of the data was published. If the data was equally collected from multiple studies please select the publication year from the study published the furthest in the past. "
              type="input"
              onChange={formik.handleChange}
              formik={formik}
              showAsterisk
              defaultValue={
                formik.values.referenceDataPublicationYear === 0
                  ? ""
                  : formik.values.referenceDataPublicationYear
              }
              formikName="referenceDataPublicationYear"
            />
            <InputItem
              name="Reference Sourcing Country"
              description="Please select the Country Name identifier in which the data was collected (not published). Please select the country where most of the data was collected from. Only use publication country if no other information is available."
              type="select"
              defaultValue={
                formik.values.referenceSourcingCountry
                  ? formik.values.referenceSourcingCountry 
                  : -1
              }
              onChange={formik.handleChange}
              formik={formik}
              formikName="referenceSourcingCountry"
              options={sortDropdown(
                configuration.countries,
                formik.values.referenceSourcingCountry ?? -1,
                "countryName"
              )}
            />
            <InputItem
              name="Reference Region Identifier"
              description="The region identifier in which the data was collected. Please select the region where most of the data was collected from."
              type="select"
              defaultValue={formik.values.referenceRegionIdentifier}
              onChange={formik.handleChange}
              formikName="referenceRegionIdentifier"
              formik={formik}
              showAsterisk
              options={sortDropdown(
                configuration.regions,
                formik.values.referenceRegionIdentifier,
                "name"
              )}
            />
            <InputItem
              name="Reference Country ISO code"
              description="Please select the country where most of the data was collected from"
              type="select"
              onChange={formik.handleChange}
              formikName="referenceSourcingCountry"
              create={true}
              defaultValue={
                formik.values.referenceSourcingCountry
                  ? formik.values.referenceSourcingCountry 
                  : -1
              }
              formik={formik}
              options={sortDropdown(
                configuration.countries,
                formik.values.referenceSourcingCountry ?? -1,
                "countryISO"
              )}
            />
          </div>
          <InputItem
            name="Data Quality Comment"
            description="Please provide any comments on assumption that were made to
              determine the criteria for data quality for this record taking
              multiple references into account."
            type="text"
            onChange={formik.handleChange}
            formik={formik}
            className={"w-full"}
            defaultValue={formik.values.dataQualityComment || ""}
            formikName="dataQualityComment"
          />
          {errors && (
            <ul className="piece-errors">
              {errors.map(([key, value]) =>
                value.map((err) => (
                  <li>
                    <b>{key}:</b> {err}
                  </li>
                ))
              )}
            </ul>
          )}
          <div className="piece-actions">
            {sessionStorage.getItem("permission") == "true" &&
              storageId != "new" && (
                <>
                  <button
                    type="submit"
                    className="piece-content-publish"
                    disabled={
                      formik.values.resourceSources.length === 0 ||
                      formik.isSubmitting ||
                      JSON.stringify(storage) != JSON.stringify(formik.values)
                    }
                  >
                    {storage.state === 0 ? "Publish" : "Unpublish"}
                  </button>
                </>
              )}
            <button
              className="piece-content-submit bg-primary-600"
              onClick={saveDraft}
              disabled={formik.isSubmitting}
            >
              Save
            </button>
            {JSON.stringify(storage) != JSON.stringify(formik.values) && (
              <b className="absolute text-sm bottom-0 text-rose-500">
                Please save before publishing new changes
              </b>
            )}
          </div>
        </form>
      </div>
      <ActivityRightPanel
        activityId={storageId}
        activityType={ActivityTypes.StorageActivity}
        auditCreatedBy={storage.researcherName}
        auditLastUpdatedBy={storage.lastUpdatedName}
        companyId={formik.values.companyId}
        isVerified={formik.values.isVerified}
        verificationComments={formik.values.verificationComments}
        verifiedByName={formik.values.verifiedByName}
        verifiedOn={formik.values.verifiedOn}
        onChange={formik.handleChange}
      />
    </>
  ) : (
    <div className="overlay">
      <LottieLoader
        lottieType="butterflyLottie"
        style={{ height: "10.25rem", width: "10.25rem" }}
      />
    </div>
  );
};

export default Storage;
