import { FC, useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { Input, Breadcrumbs } from "@mondra/ui-components";
import { GET_ALL_PIECES } from "../../../query/packaging-pieces";
import { useQuery } from "@apollo/client";
import { IPieces } from "./types";
import { getPiecesVars } from "./helpers";
import { filterEnums, getTableData } from "../../../shared/gridFunctions";
import Table from "../../../components/Table";

import "./index.scss";

const Packaging: FC = () => {
  const navigate = useNavigate();

  const [tableData, setTableData] = useState<IPieces[]>([]);
  const [columnDefs, setColumn] = useState([]);
  const [tableSearch, setTableSearch] = useState<string | null>(null);
  const [debouncedTableSearch, setDebouncedTableSearch] = useState(tableSearch);
  const [totalCount, setTotalCount] = useState<string>("");
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [startCursor, setStartCursor] = useState<string | null>(null);
  const [piecesVars, setPiecesVars] = useState<any>(getPiecesVars);
  const [order, setOrder] = useState([{ id: "ASC" }]);

  const { data, loading, error, refetch } = useQuery(GET_ALL_PIECES, {
    variables: {
      str: "",
      first: 100,
      last: null,
      order: order,
      ...getPiecesVars,
    },
  });

  const anyRefetch = (variables?: any): Promise<any> => {
    return refetch(variables);
  };

  const setCursors = () => {
    setEndCursor(data.packagingPieces.pageInfo.endCursor);
    setStartCursor(data.packagingPieces.pageInfo.startCursor);
  };

  useEffect(() => {
    const timer = setTimeout(() => setTableSearch(debouncedTableSearch), 1000);
    return () => clearTimeout(timer);
  }, [debouncedTableSearch]);

  useEffect(() => {
    refetch();
    getTableData(
      data,
      loading,
      setTableData,
      setColumn,
      setTotalCount,
      setCursors,
      "/package-pieces/piece",
      "packagingPieces",
      "packagingPiece"
    );
  }, [data]);

  useEffect(() => {
    if (tableSearch != null || !order[0].id) {
      if (!loading) {
        setPiecesVars({
          ...filterEnums(tableSearch, getPiecesVars, data),
        });
        anyRefetch({
          str: tableSearch || "",
          ...filterEnums(tableSearch, getPiecesVars, data),
          first: 100,
          after: null,
          before: null,
          last: null,
          order: order,
        });
      }
    }
  }, [tableSearch, order, loading]);

  return (
    <div className={"packaging"}>
      <div style={{ maxWidth: "50rem" }} className={"mb-4"}>
        <p className={"decoration-gray-600 mt-3"}>
          <Breadcrumbs
            routes={[{ path: "/package-pieces", title: "Packaging Table" }]}
          />
        </p>
        <h2 className={"mt-3 mb-5 text-2xl"}>Packaging</h2>
        <div className="packaging-actions">
          <Input
            onChange={(e) => setDebouncedTableSearch(e.target.value)}
            iconType="search"
            placeholder="Search"
          />
          <button
            onClick={() => navigate("/package-pieces/piece?itemId=new")}
            className={"bg-primary-600"}
          >
            Add Piece
          </button>
        </div>
      </div>
      <Table
        loading={loading}
        tableData={tableData}
        columnDefs={columnDefs}
        totalNumber={totalCount}
        endCursor={endCursor}
        startCursor={startCursor}
        refetch={refetch}
        defaultVars={piecesVars || getPiecesVars}
        setCursors={setCursors}
        tableSearch={tableSearch}
        setOrder={setOrder}
        order={order}
        suppressRowClickSelection
      />
    </div>
  );
};

export default Packaging;
