import React, { FC, useEffect, useState, EffectCallback, useMemo } from "react";
import { useAppDispatch } from "../../../hooks/redux";
import { useSearchParams, useNavigate } from "react-router-dom";
import {
  Breadcrumbs,
  Button,
  Icon,
  LottieLoader,
  Notification,
  ToggleSwitch,
  Checkbox,
  Label
} from "@mondra/ui-components";
import { useAppSelector } from "../../../hooks/redux";
import { useFormik } from "formik";
import Collapsible from "react-collapsible";
import {
  fetchProcessedIngredient,
  getConfiguration,
} from "./processedIngredientActions";
import { getFieldAudit } from "../../../redux/action-creators/auditActions";
import { sortDropdown, transformFormValues } from "../../../shared/formFunctions";
import { formSchema } from "./utils";
import {
  updateProcessedIngredients,
  createProcessedIngredients,
  getProcessedDropdowns,
  getProduceDropdowns,
  getProcessedClass,
  getProduceClass,
  addProcessedCategory,
  publishPath,
  unpublishPath,
  updateProcessedPath,
  getFAOItems,
  getProcessedIngredientsConfiguration,
} from "./api";
import { getProduceConfiguration } from '../../produce/produce-ing/api'
import { usePrompt } from "../../../hooks/usePrompt";
import {
  handlePublishStatus,
  setDefaultIngredient,
} from "../../../redux/processedIngredient";
import InputItem from "../../../components/InputItem";

import "./index.scss";
import SelectCreatable from "../../../components/CreatableSelect";
import { showCorrelationIdError } from "../../../redux/correlationIdSlice";
import { addProduceCategory, publishPath as publishProducePath, unpublishPath as unpublishProducePath, updateProducePath } from "../../produce/produce-ing/api";
import PackagingForm from "../../../components/Packaging/Packaging";
import ActivityRightPanel from "../../../components/ActivityRightPanel";
import { ActivityTypes } from "../../../const";

interface Dropdown {
  id: number;
  code: string;
  name: string;
  state: number;
}

interface FAODropdown extends Dropdown {
  faoProcessedTradeType: number;
  faoProcessedProductionType: number;
}

const UPSTREAM_ING_SUBCAT_KEY = 'upstreamIngredientSubcategoryId'

const ProcessedIngredient: FC = ({}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const { loading, ingredient, configuration } = useAppSelector(
    (state) => state.processedIngredientSlice
  );
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [showError, setShowError] = useState<string>("");
  const [errors, setErrors] = useState<[any, string[]][]>();
  const [showPublished, setShowPublished] = useState<boolean>(false);
  const [classList, setClassList] = useState<[] | Dropdown[]>([]);
  const [produceClass, setProduceClass] = useState<[] | Dropdown[]>([]);
  const [activity, setActivity] = useState<[] | Dropdown[] | null>(null);
  const [category, setCategory] = useState<[] | Dropdown[] | null>(null);
  const [subcategory, setSubcategory] = useState<[] | FAODropdown[] | null>(null);
  const [upstream, setUpstream ] = useState<[] | Dropdown[] | null>(null);
  let ingredientId = searchParams.get("itemId") || "";
  const isNew = ingredientId === "new"

  const formik = useFormik({
    initialValues: {
      ...ingredient,
    },
    validationSchema: formSchema,
    onSubmit: async () => {
      if (ingredient.state === 0) {
        publishPath("functionalunit", ingredientId)
          .then(() => {
            dispatch(handlePublishStatus(1));
            setShowPublished(true);
            setTimeout(() => {
              setShowPublished(false);
            }, 4000);
          })
          .catch((error) =>
            setShowError(error.response.data.title)
          );
      } else {
        unpublishPath("functionalunit", ingredientId)
          .then(() => {
            dispatch(handlePublishStatus(0));
            setShowPublished(true);
            setTimeout(() => {
              setShowPublished(false);
            }, 4000);
          })
          .catch((error) =>
            setShowError(error.response.data.title)
          );
      }
    },
  });

  const saveDraft = async (e) => {
    e.preventDefault();
    const {values} = formik;

    const tValues = transformFormValues(values)
    
    for (let i = 0; i < tValues.packagingPieces.length; i++) {
      if (typeof tValues.packagingPieces[i].packagingPieceId === "string") {
        tValues.packagingPieces[i].packagingPieceId =
          +tValues.packagingPieces[i].packagingPieceId;
      }
    }
    if (isNew) {
      try {
        await createProcessedIngredients(tValues).then(() => {
          navigate("/processed-ingredients");
        });
      } catch (error: any) {
        if (error.showCorrelationId) {
          dispatch(showCorrelationIdError(error.CorrelationId));
        } else {
          setShowError(
            typeof error.response.data === "string" 
              ? error.response.data
              : typeof error.response.data.errors === "string"
              ? error.response.data.errors
              : Object.values(error.response.data.errors).toString().replace(/!,/g, '!\n')
          );
          setTimeout(() => {
            setShowError("");
          }, 5000);
          setErrors(
            typeof error.response.data.errors === "string" 
              ? error.response.data.errors
              : Object.entries(error.response.data.errors)
          );
        }
      }
    } else {
      try {
        await updateProcessedIngredients(ingredientId, tValues)            
          .then(() => {
            dispatch(getConfiguration(ingredientId));
            dispatch(fetchProcessedIngredient(ingredientId, () => navigate("/processed-ingredients")));
          })
          .then(() => {
            setShowNotification(true);
            setTimeout(() => {
              setShowNotification(false);
            }, 5000);
          });
      } catch (error: any) {
        if (error.showCorrelationId) {
          dispatch(showCorrelationIdError(error.CorrelationId));
        } else {
          setShowError(
            typeof error.response.data === "string" 
              ? error.response.data
              : typeof error.response.data.errors === "string"
              ? error.response.data.errors
              : Object.values(error.response.data.errors).toString().replace(/!,/g, '!\n')
          );
          setTimeout(() => {
            setShowError("");
          }, 5000);
          setErrors(
            typeof error.response.data.errors === "string" 
              ? error.response.data.errors
              : Object.entries(error.response.data.errors)
          );
        }
      }
    }
  }

  const loadFaoTradeOptions = (
    inputValue: string,
    callback: (options: Dropdown[]) => void
  ) => {
    if(formik.values.produceSubcategoryId && inputValue.length > 1 ) {
      var x = isNew
      var faoType = isNew ?
        subcategory?.find(el => el.id === formik.values.produceSubcategoryId)?.faoProcessedTradeType
        :configuration.path.produceSubcategoryFAOProcessedTradeType;
      getFAOItems(faoType, inputValue).then((res) => {
        let data = res.data.map((el) => ({
          label: el.name,
          value: el.id,
        }))
        callback(data)
      })
    }
  };

  const loadFaoProductionOptions = (
    inputValue: string,
    callback: (options: Dropdown[]) => void
  ) => {
    if(formik.values.produceSubcategoryId && inputValue.length > 1 ) {
      var x = isNew
      var faoType = isNew ?
        subcategory?.find(el => el.id === formik.values.produceSubcategoryId)?.faoProcessedProductionType
        :configuration.path.produceSubcategoryFAOProcessedProductionType;
      getFAOItems(faoType, inputValue).then((res) => {
        let data = res.data.map((el) => ({
          label: el.name,
          value: el.id,
        }))
        callback(data)
      })
    }
  };


  useEffect((): ReturnType<EffectCallback> => {
    if (ingredientId == "") {
      navigate("/not-found");
    }
    if (isNew) {
      dispatch(getConfiguration("0"));
      getProcessedClass().then((res) => setClassList(res.data));
      getProduceClass().then((res) => setProduceClass(res.data));
    }
    if (!isNew) {
      dispatch(getConfiguration(ingredientId));
      dispatch(
        fetchProcessedIngredient(ingredientId, () =>
          navigate("/processed-ingredients")
        )
      );
    }
    return () => {
      dispatch(setDefaultIngredient());
    };
  }, []);

  useEffect(() => {
    const getUpstreamConfiguration = async () => {
      formik.setValues({ ...ingredient });
      if(ingredient.upstreamIngredientId){
          let upstreamSubCategoryId
          if(ingredient.upstreamIngredientType === 0){
            const upstreamProduceConfigRes = await getProduceConfiguration(`${ingredient.upstreamIngredientId}`)
            upstreamSubCategoryId = upstreamProduceConfigRes?.data?.path.subcategoryId
          } else {
            const upstreamIngConfigRes = await getProcessedIngredientsConfiguration(`${ingredient.upstreamIngredientId}`)
            upstreamSubCategoryId = upstreamIngConfigRes?.data?.path?.produceSubcategoryId
          }
          formik.setFieldValue(UPSTREAM_ING_SUBCAT_KEY, upstreamSubCategoryId);
      }
    }
    getUpstreamConfiguration()
  }, [ingredient])

  useEffect(() => {
    if(configuration?.path?.produceCategoryId && !isNew){
      const getSubCategoriesData = async () => {
        const subCategoryResp = await getProduceDropdowns(`subcategory?categoryId=${configuration.path.produceCategoryId}`)
        setSubcategory(subCategoryResp?.data)
      }
      getSubCategoriesData()
    }
  }, [ingredientId, configuration?.path?.produceCategoryId])

  useEffect(() => {
    if(classList.length > 0){
      getProcessedDropdowns(
        `activity?classId=${
          classList[classList.length - 1].id
        }`
      ).then((res) => setActivity(res.data));
    }
    if(produceClass.length > 0){
      getProduceDropdowns(
        `category?classId=${
          produceClass[produceClass.length - 1].id
        }`
      ).then((res) => setCategory(res.data));
    }
  }, [classList, produceClass]);


  const addProcess = (values, array) => {
    formik.setValues({
      ...formik.values,
      [array]: Array.isArray(values) ? [...formik.values[array], ...values] :[...formik.values[array], values],
    });
  };

  const deleteProcess = (idx, array) => {
    const newComponents = formik.values[array].filter(
      (el, index) => index != +idx
    );
    formik.setValues({
      ...formik.values,
      [array]: newComponents,
    });
  };

  const handleChangeItemsNumber = (index: number) => (event: any) => {
    var parsed = parseInt(event.target.value)
    var itemsNumber = isNaN(parsed)? undefined : parsed;
    const updatedPieces = formik.values.packagingPieces.map((piece) => {
      if (piece.order === index) {
        return {
          ...piece,
          itemsNumber: itemsNumber,
        };
      }
      return piece; 
    });
    formik.setValues({
      ...formik.values,
      packagingPieces: updatedPieces,
    });
  };

  const isItemsNumberValid =  (index: number) => {
    return formik.values.packagingPieces[index]?.itemsNumber !== undefined &&  (formik.values.packagingPieces[index]?.itemsNumber ?? 0 )> 0;
  }

  const onAdd = () => {
    formik.setValues({
      ...formik.values,
      packagingPieces: [...formik.values.packagingPieces, {
        packagingPieceId : 0,
        packagingType : 1,
        order: formik.values.packagingPieces.length,
        itemsNumber: null
      } as any],
    });
  }

  function canSaveForm(formik) {  
    return formik.values.name === "" 
      || formik.values.upstreamIngredientId === 0
      || formik.isSubmitting 
      || formik.values.packagingPieces.filter(f=> f.packagingType === 1 
                && (f.itemsNumber === undefined || 
                    f.itemsNumber === null || 
                    f.itemsNumber === 0 || 
                    !configuration.packagingPieces.some((el) => el.id === f.packagingPieceId || el.id.toString() === f.packagingPieceId))).length !== 0;
  }

useEffect(()=>{
  if(formik.values[UPSTREAM_ING_SUBCAT_KEY] > 0) {
    if(formik.values.upstreamIngredientType === 0){
      getProduceDropdowns(
        `functionalUnit?subcategoryId=${formik.values[UPSTREAM_ING_SUBCAT_KEY]}`
      ).then((res) => setUpstream(res.data))
    } else {
      getProcessedDropdowns(
        `functionalUnit?produceSubcategoryId=${formik.values[UPSTREAM_ING_SUBCAT_KEY]}`
      ).then((res) => setUpstream(res.data))
    }
  }
},[formik.values.upstreamIngredientType, formik.values[UPSTREAM_ING_SUBCAT_KEY]])

  usePrompt(
    "There are unsaved changes, do you want to leave?",
    ingredientId !== 'new' ? JSON.stringify(ingredient) != JSON.stringify({ ...formik.values, upstreamIngredientSubcategoryId: undefined }) : false,
  );

  const primaryPiece = useMemo(() => configuration.packagingPieces?.filter(pp => pp.packagingType == 0), [configuration])
  const distributionPiece = useMemo(() => configuration.packagingPieces?.filter(pp => pp.packagingType == 1), [configuration])

  return (isNew && classList.length != 0) ||
    loading !== true ||
    isNew ? (
    <>
      <div className="piece content">
        {showError !== "" && (
          <div className={"piece-notification"}>
            <Notification type="error" label="Oops:" description={showError} />
          </div>
        )}
        {showPublished && (
          <div className={"piece-notification"}>
            <Notification
              type="success"
              label="Success:"
              description="Status was updated!"
              isToast={false}
            />
          </div>
        )}
        {showNotification && (
          <div className={"piece-notification"}>
            <Notification
              type="success"
              label="Success:"
              description="Item was updated!"
              isToast={false}
            />
          </div>
        )}
        <div className="mt-3 mb-3">
          <Breadcrumbs
            routes={[
              {
                path: "/processed-ingredients",
                title: "Processed Ingredients Table",
              },
              {
                path: `/processed-ingredients/ingredient?itemId=${ingredientId}`,
                title: "Processed Ingredient",
              },
            ]}
          />
        </div>
        {!isNew && (
          <>
            <div className="piece-header">
              <div
                className={`piece-header-status ${
                  ingredient.state === 0 ? "bg-red-500" : "bg-emerald-500"
                }`}
              >
                {ingredient.state === 0 ? "Not Published" : "Published"}
              </div>
              {ingredient?.lastPublishedDate ? (
                <span className={"inputItem-header"}>
                  {`Last published date: ${new Date(
                    ingredient.lastPublishedDate
                  ).toLocaleString()}
                  `}
                </span>
              ) : (
                ""
              )}
            </div>
          </>
        )}
        <div>
          <h5 className="mt-5 mb-5">Indicators information</h5>
          <ul>
            {/* <li className="flex mb-5">
              <Icon type="edit" className="text-cyan-400 mr-3" /> - press this
              button if you want to edit field information
            </li> */}
            <li className="flex mb-5">
              <Icon type="warning" className="text-rose-700 mr-3" /> - this
              indicator shows you that field value is not published. Press it
              for publishing field value (only for Managers)
            </li>
            <li className="flex mb-5">
              <Icon type="checkmarkOutline" className="text-emerald-700 mr-3" />{" "}
              - this indicator shows you that field value is published. Press it
              for unpublishing field value (only for Managers)
            </li>
            {ingredientId !== "new" &&
            <li className="flex">
              <Icon type="informationSquare" className="text-green-200 mr-3" /> - press this
              button if you want to show audit information of the field
            </li>}
          </ul>
        </div>
        <form onSubmit={formik.handleSubmit} className="piece-content">
          <h5 className={"mt-5"}>Processed Ingredient</h5>
          {isNew ? (
            <div className="piece-content-inputs">
              <SelectCreatable
                name="Activity"
                create={true}
                formik={formik}
                showAsterisk
                disabled={activity === null}
                codeDisabled={false}
                formikName="activityId"
                onCreateOption={(newValue) =>
                  addProcessedCategory("activity", {
                    name: newValue,
                    classId: classList[classList.length - 1].id,
                  }).then(() =>
                    getProcessedDropdowns(
                      `activity?classId=${classList[classList.length - 1].id}`
                    ).then((res) => {
                      setActivity(res.data);
                      formik.setFieldValue(
                        "activityId",
                        res.data[res.data.length - 1].id
                      );
                    })
                  )
                }
                options={
                  (activity &&
                    activity.map((el) => ({
                      label: el.name,
                      value: el.id,
                    }))) ||
                  []
                }
                unpublished={
                  activity &&
                  activity.filter((el) => el.id === formik.values.activityId)[0]
                    ?.state
                }
                code={
                  activity &&
                  activity.filter((el) => el.id === formik.values.activityId)[0]
                    ?.code
                }
                onPublishClick={() => {
                  publishPath("activity", formik.values.activityId).then(() =>
                    getProcessedDropdowns(
                      `activity?subclassId=${formik.values.subclassId}`
                    ).then((res) => setActivity(res.data))
                  ).catch(() => {
                    setShowError("Please publish subclass first!");
                    setTimeout(() => {
                      setShowError("");
                    }, 4000);
                  });
                }}
                onUnpublishClick={() => {
                  unpublishPath("activity", formik.values.activityId).then(() =>
                    getProcessedDropdowns(
                      `activity?subclassId=${formik.values.subclassId}`
                    ).then((res) => setActivity(res.data))
                  );
                }}
                onEditClick={(name, code) => {
                  updateProcessedPath("activity", {
                    name,
                    code,
                    subclassId: formik.values.subclassId,
                    id: formik.values.activityId,
                  }).then(() =>
                    getProcessedDropdowns(
                      `activity?subclassId=${formik.values.subclassId}`
                    ).then((res) => setActivity(res.data))
                  );
                }}
              />
              <SelectCreatable
                name="Category"
                create={true}
                formik={formik}
                disabled={category === null}
                codeDisabled={false}
                showAsterisk
                formikName="categoryId"
                onCreateOption={(newValue) =>
                  addProduceCategory("category", {
                    name: newValue,
                    classId: produceClass[produceClass.length - 1].id,
                  }).then(() =>
                    getProduceDropdowns(
                      `category?classId=${produceClass[produceClass.length - 1].id}`
                    ).then((res) => {
                      setCategory(res.data);
                      formik.setFieldValue(
                        "categoryId",
                        res.data[res.data.length - 1].id
                      );
                      getProduceDropdowns(
                        `subcategory?categoryId=${
                          res.data[res.data.length - 1].id
                        }`
                      ).then((res) => setSubcategory(res.data));
                    })
                  )
                }
                onChange={(id) =>
                  getProduceDropdowns(`subcategory?categoryId=${id}`).then(
                    (res) => setSubcategory(res.data)
                  )
                }
                options={
                  (category &&
                    category.map((el) => ({
                      label: el.name,
                      value: el.id,
                    }))) ||
                  []
                }
                unpublished={
                  category &&
                  category.filter((el) => el.id === formik.values.categoryId)[0]
                    ?.state
                }
                code={
                  category &&
                  category.filter((el) => el.id === formik.values.categoryId)[0]
                    ?.code
                }
                onPublishClick={() => {
                  publishProducePath("category", formik.values.categoryId).then(() =>
                    getProcessedDropdowns(
                      `category?classId=${produceClass[produceClass.length - 1].id}`
                    ).then((res) => setCategory(res.data))
                  ).catch(() => {
                    setShowError("Please publish activity first!");
                    setTimeout(() => {
                      setShowError("");
                    }, 4000);
                  });
                }}
                onUnpublishClick={() => {
                  unpublishProducePath("category", formik.values.categoryId).then(() =>
                    getProcessedDropdowns(
                      `category?classId=${produceClass[produceClass.length - 1].id}`
                    ).then((res) => setCategory(res.data))
                  );
                }}
                onEditClick={(name, code) => {
                  updateProducePath("category", {
                    name,
                    code,
                    classId: 1,
                    id: formik.values.categoryId
                  }).then(() =>
                  getProcessedDropdowns(
                    `category?classId=${produceClass[produceClass.length - 1].id}`
                  ).then((res) => setCategory(res.data))
                  );
                }}
              />
              <SelectCreatable
                name="Subcategory"
                create={true}
                formik={formik}
                disabled={subcategory === null}
                codeDisabled={false}
                formikName="produceSubcategoryId"
                showAsterisk
                onCreateOption={(newValue) =>
                  addProduceCategory("subcategory", {
                    name: newValue,
                    categoryId: formik.values.categoryId,
                  }).then(() =>
                    getProduceDropdowns(
                      `subcategory?categoryId=${formik.values.categoryId}`
                    ).then((res) => {
                      setSubcategory(res.data);
                      formik.setFieldValue(
                        "produceSubcategoryId",
                        res.data[res.data.length - 1].id
                      );
                    })
                  )
                }
                onChange={(id) => {
                    formik.setFieldValue("faoTradeId", undefined);
                    formik.setFieldValue("faoProductionId", undefined);
                    formik.setFieldValue("faoTradeSource", undefined);
                    formik.setFieldValue("faoProductionSource", undefined);
                  }
                }
                options={
                  (subcategory &&
                    subcategory.map((el) => ({
                      label: el.name,
                      value: el.id,
                    }))) ||
                  []
                }
                unpublished={
                  subcategory &&
                  subcategory.filter(
                    (el) => el.id === formik.values.produceSubcategoryId
                  )[0]?.state
                }
                code={
                  subcategory &&
                  subcategory.filter(
                    (el) => el.id === formik.values.produceSubcategoryId
                  )[0]?.code
                }
                onPublishClick={() => {
                  publishProducePath("subcategory", formik.values.produceSubcategoryId).then(
                    () =>
                      getProduceDropdowns(
                        `subcategory?categoryId=${formik.values.categoryId}`
                      ).then((res) => setSubcategory(res.data))
                  ).catch(() => {
                    setShowError("Please publish category first!");
                    setTimeout(() => {
                      setShowError("");
                    }, 4000);
                  });
                }}
                onUnpublishClick={() => {
                  unpublishProducePath(
                    "subcategory",
                    formik.values.produceSubcategoryId
                  ).then(() =>
                    getProduceDropdowns(
                      `subcategory?categoryId=${formik.values.categoryId}`
                    ).then((res) => setSubcategory(res.data))
                  );
                }}
                onEditClick={(name, code) => {
                  updateProducePath("subcategory", {
                    name,
                    code,
                    categoryId: formik.values.categoryId,
                    id: formik.values.produceSubcategoryId,
                  }).then(() =>
                    getProduceDropdowns(
                      `subcategory?categoryId=${formik.values.categoryId}`
                    ).then((res) => setSubcategory(res.data))
                  );
                }}
              />
              <InputItem
                name="Processed Ingredient Name"
                description=""
                type="input"
                disabled={formik.values.produceSubcategoryId === 0}
                formik={formik}
                onChange={formik.handleChange}
                defaultValue={formik.values.name}
                formikName="name"
                showAsterisk
              />
            </div>
          ) : (
            <div className="piece-content-inputs">
              <InputItem
                name="Activity"
                description=""
                type="input"
                formik={formik}
                onChange={formik.handleChange}
                disabled={true}
                codeDisabled={false}
                defaultValue={configuration.path.activity}
                formikName="width"
                unpublished={configuration.path.activityState}
                edit={false}
                auditHistory={true}
                code={configuration.path.activityCode}
                onPublishClick={() => {
                  publishPath("activity", configuration.path.activityId).then(
                    () => dispatch(getConfiguration(ingredientId))
                  ).catch(() => {
                    setShowError("Please publish subclass first!");
                    setTimeout(() => {
                      setShowError("");
                    }, 4000);
                  });
                }}
                onUnpublishClick={() => {
                  unpublishPath("activity", configuration.path.activityId).then(
                    () => dispatch(getConfiguration(ingredientId))
                  );
                }}
                onEditClick={(name, code) => {
                  updateProcessedPath("activity", {
                    name,
                    code,
                    classId: configuration.path.classId,
                    id: configuration.path.activityId,
                  }).then(() => dispatch(getConfiguration(ingredientId)));
                }}
                onGetAuditClick={() => dispatch(
                  getFieldAudit(
                    "Taxonomy/ProcessedIngredient",
                    "activity",
                    configuration.path?.activityId
                  )
                )}
              />
              <InputItem
                name="Category"
                description=""
                type="input"
                formik={formik}
                onChange={formik.handleChange}
                disabled={true}
                codeDisabled={false}
                defaultValue={configuration.path.produceCategory}
                formikName="width"
                unpublished={configuration.path.produceCategoryState}
                edit={false}
                auditHistory={true}
                code={configuration.path.produceCategoryCode}
                onPublishClick={() => {
                  publishProducePath("category", configuration.path.produceCategoryId).then(
                    () => dispatch(getConfiguration(ingredientId))
                  ).catch(() => {
                    setShowError("Please publish activity first!");
                    setTimeout(() => {
                      setShowError("");
                    }, 4000);
                  });
                }}
                onUnpublishClick={() => {
                  unpublishProducePath("category", configuration.path.produceCategoryId).then(
                    () => dispatch(getConfiguration(ingredientId))
                  );
                }}
                onEditClick={(name, code) => {
                  updateProducePath("category", {
                    name,
                    code,
                    classId: configuration.path.classId,
                    id: configuration.path.produceCategoryId,
                  }).then(() => dispatch(getConfiguration(ingredientId)));
                }}
                onGetAuditClick={() => dispatch(
                  getFieldAudit(
                    "Taxonomy/Produce",
                    "category",
                    configuration.path?.produceCategoryId
                  )
                )}
              />
              <InputItem
                name="Subcategory"
                description=""
                type="input"
                formik={formik}
                onChange={formik.handleChange}
                disabled={true}
                codeDisabled={false}
                defaultValue={configuration.path.produceSubcategory}
                formikName="width"
                unpublished={configuration.path.produceSubcategoryState}
                edit={false}
                auditHistory={true}
                code={configuration.path.produceSubcategoryCode}
                onPublishClick={() => {
                  publishProducePath("subcategory", configuration.path.produceSubcategoryId)
                    .then(() => dispatch(getConfiguration(ingredientId)))
                    .catch(() => {
                      setShowError("Please publish category first!");
                      setTimeout(() => {
                        setShowError("");
                      }, 4000);
                    });
                }}
                onUnpublishClick={() => {
                  unpublishProducePath(
                    "subcategory",
                    configuration.path.produceSubcategoryId
                  ).then(() => dispatch(getConfiguration(ingredientId)));
                }}
                onEditClick={(name, code) => {
                  updateProducePath("subcategory", {
                    name,
                    code,
                    categoryId: configuration.path.produceCategoryId,
                    id: configuration.path.produceCategoryId,
                  }).then(() => dispatch(getConfiguration(ingredientId)));
                }}
                onGetAuditClick={() => dispatch(
                  getFieldAudit(
                    "Taxonomy/Produce",
                    "subcategory",
                    configuration.path?.produceSubcategoryId
                  )
                )}
              />
              <InputItem
                name="Processed Ingredient Name"
                description=""
                type="input"
                formik={formik}
                onChange={formik.handleChange}
                defaultValue={formik.values.name}
                formikName="name"
              />
            </div>
          )}
          <h5 className={"mt-5"}>Unit info</h5>
          <div className="piece-content-inputs">
            <InputItem
                name="Upstream Ingredient Type"
                description="Please specify the upstream ingredient type of this entry"
                type="select"
                formikName="upstreamIngredientType"
                formik={formik}
                defaultValue={formik.values.upstreamIngredientType}
                disabled={formik.values.produceSubcategoryId == 0}
                showAsterisk
                onChange={(e) => {
                  const typeId = (e.target as any).value ? +(e.target as any).value  : -1
                  console.log('typeId', typeId)
                  formik.setFieldValue("upstreamIngredientType", typeId);
                  formik.setFieldValue(UPSTREAM_ING_SUBCAT_KEY, '')
                  formik.setFieldValue("upstreamIngredientId", '');
                }}
                options={
                  (configuration.upstreamIngredientTypes &&
                    configuration.upstreamIngredientTypes.map((el) => ({
                      label: el.name,
                      value: el.id,
                    }))) ||
                  []
                }
              />
              {subcategory ? (
              <InputItem
                name="Upstream Subcategory"
                description=""
                create
                type="select"
                disabled={formik.values.upstreamIngredientType === -1}
                formikName={UPSTREAM_ING_SUBCAT_KEY}
                formik={formik}
                showAsterisk
                defaultValue={formik.values[UPSTREAM_ING_SUBCAT_KEY] || ''}
                onChange={(e) => {
                  formik.setFieldValue(UPSTREAM_ING_SUBCAT_KEY, +(e.target as any).value);
                }}
                options={
                  subcategory ?
                    subcategory.map((el) => ({
                      label: el.name,
                      value: el.id,
                    })) :
                  []
                }
              />) : undefined}
              <InputItem
                name="Upstream Ingredient"
                description=""
                type="select"
                disabled={!formik.values[UPSTREAM_ING_SUBCAT_KEY]}
                formikName="upstreamIngredientId"
                formik={formik}
                showAsterisk
                defaultValue={formik.values.upstreamIngredientId}
                onChange={(e) => {
                  formik.setFieldValue("upstreamIngredientId", +(e.target as any).value);
                }}
                options={
                  (upstream &&
                    upstream.map((el) => ({
                      label: el.name,
                      value: el.id,
                    }))) ||
                  []
                }
              />
              
            <InputItem
              name="External code"
              description=""
              type="input"
              disabled
              formik={formik}
              onChange={formik.handleChange}
              defaultValue={formik.values.code || ""}
              formikName="code"
            />
            <InputItem
              name="Processed Ingredient Name Aliases"
              description=""
              type="input"
              formik={formik}
              onChange={formik.handleChange}
              defaultValue={formik.values.functionalUnitAliases || ""}
              formikName="functionalUnitAliases"
            />                        
          </div>
          <h5 className={"mt-5"}>FAO</h5>
          <div className="piece-content-inputs">
            <InputItem
              name="FAO Trade Item"
              description=""
              type="autocompleteLockup"
              disabled={formik.values.produceSubcategoryId === 0}
              loadOptions={loadFaoTradeOptions}
              formik={formik}
              onChange={formik.handleChange}
              formikName="faoTradeId"
              defaultValue= { configuration?.path?.faoTradeId ? {
                label: configuration.path.faoTradeName,
                value: configuration.path.faoTradeId,
              } : undefined }
            />

            <InputItem
              name="FAO Trade Source"
              description=""
              type="select"
              formik={formik}
              onChange={formik.handleChange}
              formikName="faoTradeSource"
              showAsterisk={true}
              defaultValue={formik.values.faoTradeSource}
              options={sortDropdown(
                configuration.faoSource,
                formik.values.faoTradeSource,
                "description"
              )}
            />
            <InputItem
              name="FAO Production Item"
              description=""
              type="autocompleteLockup"
              disabled={formik.values.produceSubcategoryId === 0}
              loadOptions={loadFaoProductionOptions}
              formik={formik}
              onChange={formik.handleChange}
              formikName="faoProductionId"
              defaultValue= { configuration?.path?.faoProductionId ? {
                label: configuration.path.faoProductionName,
                value: configuration.path.faoProductionId,
              } : undefined }
            />

            <InputItem
              name="FAO Production Source"
              description=""
              type="select"
              formik={formik}
              defaultValue={formik.values.faoProductionSource}
              onChange={formik.handleChange}
              formikName="faoProductionSource"
              showAsterisk={true}
              options={sortDropdown(
                configuration.faoSource,
                ingredient.faoProductionSource,
                "description"
              )}
            />
          </div>
          <h5 className={"mt-5"}>Transport and Storage</h5>
          <div className="piece-content-inputs">
            <InputItem
              name="Storage/Transport condition"
              description=""
              type="select"
              formik={formik}
              defaultValue={formik.values.transportCondition}
              onChange={formik.handleChange}
              formikName="transportCondition"
              options={sortDropdown(
                configuration.transportCondition,
                formik.values.transportCondition || -1,
                "description"
              )}
            />
            <div className="inputItem mx-1 mt-6">
              <div className="flex justify-start items-center gap-3">
                <Label
                  className={`flex-wrap items-center mt-6`}
                  title="Air Freight Transport"
                >
                  <Checkbox
                    id = "isTransportAirFreight"
                    key= "isTransportAirFreight"
                    checked={formik.values.isTransportAirFreight}
                    onChange={(e) => {
                      formik.setFieldValue(`isTransportAirFreight`, e.currentTarget.checked)
                      formik.handleChange(e);
                    }}
                    size={18}
                  /> Air Freight Transport
                </Label>
              </div>
            </div>
            <InputItem
              name="Storage Density Type"
              description=""
              type="select"
              formik={formik}
              defaultValue={formik.values.storageFoodCategory}
              onChange={formik.handleChange}
              formikName="storageFoodCategory"
              options={sortDropdown(
                configuration.storageFoodCategory,
                formik.values.storageFoodCategory || -1,
                "description"
              )}
            />

            <InputItem
              name="Storage duration in hours"
              description=""
              type="input"
              addonTitle="h"
              onChange={formik.handleChange}
              formikName="storageDurationInHours"
              formik={formik}
              defaultValue={formik.values.storageDurationInHours}
            />
          </div>
          <h5 className={"mt-5"}>Storage References</h5>
          <h6>Duration</h6>
          <div className={"piece-content-inputs mb-5"}>
            <InputItem
              name="Secondary Data Reference Source Type"
              description="If multiple references sources where used to generate this record please indicate the overall reference source type by select the reference where the majority of the data was retrieved from, if this is not possible please select the source type with the lowest ranking. If this is not possible take the reference which was published dating back the longest. Please note “Peer-reviewed published literature” can be a journal, conference publication of book chapter."
              type="select"
              defaultValue={formik.values.secondaryDataReferenceSourceType}
              onChange={formik.handleChange}
              formik={formik}
              formikName="secondaryDataReferenceSourceType"
              options={sortDropdown(
                configuration.secondaryDataReferenceSourceType,
                formik.values.secondaryDataReferenceSourceType ?? -1,
                "description"
              )}
            />
          </div>
          <div className={"piece-content-inputs mb-5"}>
            <InputItem
              name="Reference Author Name"
              description="Please indicate author of the referene"
              type="input"
              onChange={formik.handleChange}
              formikName="referenceAuthorName"
              formik={formik}
              defaultValue={formik.values.referenceAuthorName || ""}
            />
            <InputItem
              name="Reference Link"
              description="Please list the references that were used to create this record."
              type="input"
              onChange={formik.handleChange}
              formikName="referenceLink"
              formik={formik}
              defaultValue={formik.values.referenceLink || ""}
            />
            <InputItem
              name="References Sampling Year"
              description="The year in which most of the data was collected. If this is a time frame please choose the end date. If the data was equally collected from multiple studies please select the sourcing year from the study published the furthest in the past."
              type="input"
              onChange={formik.handleChange}
              formik={formik}
              defaultValue={formik.values.referencesSamplingYear || ""}
              formikName="referencesSamplingYear"
            />
            <InputItem
              name="Reference Data Publication Year"
              description="The year in which most of the data was published. If the data was equally collected from multiple studies please select the publication year from the study published the furthest in the past. "
              type="input"
              onChange={formik.handleChange}
              formik={formik}
              defaultValue={formik.values.referenceDataPublicationYear || ""}
              formikName="referenceDataPublicationYear"
            />
            <InputItem
              name="Reference Sourcing Country"
              description="Please select the Country Name identifier in which the data was collected (not published). Please select the country where most of the data was collected from. Only use publication country if no other information is available."
              type="select"
              defaultValue={
                formik.values.referenceSourcingCountry
                  ? formik.values.referenceSourcingCountry
                  : -1
              }
              onChange={formik.handleChange}
              formik={formik}
              formikName="referenceSourcingCountry"
              options={sortDropdown(
                configuration.countries,
                formik.values.referenceSourcingCountry ?? -1,
                "countryName"
              )}
            />
            <InputItem
              name="Reference Country ISO Code"
              description="Please select the Country Name identifier in which the data was collected (not published). Please select the country which most of the data was collected from. Only use publication country if no other information is available."
              type="select"
              defaultValue={formik.values.referenceSourcingCountry || -1}
              onChange={formik.handleChange}
              formik={formik}
              formikName="referenceSourcingCountry"
              options={sortDropdown(
                configuration.countries,
                formik.values.referenceSourcingCountry ?? -1,
                "countryISO"
              )}
            />
          </div>
          <InputItem
            name="Data Quality Comment"
            description="Please provide any comments on assumption that were made to
              determine the criteria for data quality for this record taking
              multiple references into account."
            type="text"
            onChange={formik.handleChange}
            formik={formik}
            className={"w-full"}
            defaultValue={formik.values.dataQualityComment || ""}
            formikName="dataQualityComment"
          />
          <h6>Food Category</h6>
          <div className={"piece-content-inputs mb-5"}>
            <InputItem
              name="Reference Link"
              description="Please list the references that were used to create this record."
              type="input"
              onChange={formik.handleChange}
              formikName="storageReferenceLink"
              formik={formik}
              defaultValue={formik.values.storageReferenceLink || ""}
            />
          </div>
          {errors && (
            Array.isArray(errors) ? (
              <ul className="piece-errors">
                {errors.map(([key, value]) =>
                    value.map((err) => (
                      <li>
                        <b>{key}:</b> {err}
                      </li>
                    ))
                  )}
              </ul>
            ) : (
              <ul className="piece-errors">
                <li>{errors}</li>
              </ul>
            )
          )}
          <div className={"piece-content-collapsible"}>
            <h5 className={"mt-5"}>Packaging</h5>
            {formik.values.packagingPieces?.length === 0 && (
              <span className="text-rose-600">
                You should have at least one packaging piece
              </span>
            )}
            <br />
            {formik.values.packagingPieces?.length > 0 &&
                  <Collapsible
                    trigger={
                      <div className="collapsible_trigger">
                        <span>
                          {
                            configuration.packagingPieces.find(p=>p.id == formik.values.packagingPieces[0]?.packagingPieceId)?.displayName
                          }
                        </span>
                        <Icon className="collapsible_icon" type="chevronDown" />
                      </div>
                    }
                  >
                    <div className="piece-content-inputs">
                      <InputItem
                        name="Primary Packaging piece"
                        className="w-4/6"
                        description=""
                        type="select"
                        formik={formik}
                        onChange={formik.handleChange}
                        defaultValue={formik.values.packagingPieces[0]?.packagingPieceId}
                        formikName={`packagingPieces[${0}].packagingPieceId`}
                        options={sortDropdown(primaryPiece, 0, "displayName")}
                      />
                      {formik.values.packagingPieces.filter(f=> f.packagingType === 1)?.map((item, index)=><div key={item.id} className="w-full flex justify-start items-end gap-4">
                        <InputItem
                          className="w-fit"
                          valid={formik.values.packagingPieces[index+1]?.packagingPieceId !== 0}
                          name={index === 0 ? "Distribution Packaging piece" : ""}
                          description={index === 0 ? "Please specify the distribution packaging piece of this entry" : "" }
                          type="select"
                          formik={formik}
                          onChange={formik.handleChange}
                          defaultValue={formik.values.packagingPieces[index+1]?.packagingPieceId}
                          formikName={`packagingPieces[${index+1}].packagingPieceId`}
                          options={sortDropdown(distributionPiece, 0, "displayName")}
                          index={index}
                        />
                        <InputItem
                          name={index === 0 ? "Items Number": ""}
                          description={index === 0 ? "Number previous items": ""}
                          onChange={handleChangeItemsNumber(index+1)}
                          type="input"
                          valid={isItemsNumberValid(index+1)}
                          defaultValue={formik.values.packagingPieces[index+1]?.itemsNumber}
                          index={index}
                        /> 
                        <Button variant="secondary" iconType="close" className="pr-1 mt-4" onClick={() => deleteProcess(index + 1, "packagingPieces")}/>
                      </div>)}
                    </div>
                    <div className="w-full flex justify-end items-center mt-6 gap-4">
                      <Button iconType="add" onClick={onAdd} disabled={formik.values.packagingPieces.filter(f=> f.packagingType === 0).length === 0}>
                          Distribution packaging
                      </Button>
                      <Button
                        onClick={() => formik.setValues({
                          ...formik.values,
                          packagingPieces: [],
                        })}
                        variant="danger"
                        iconType="close"
                      >
                        Delete
                      </Button>
                    </div>
                  </Collapsible>
              }
            <PackagingForm
              addProcess={addProcess}
              disabled={formik.values.packagingPieces?.length > 0}
              packagingPieces={configuration.packagingPieces}
            />
          </div>
          <div className="piece-actions">
            {sessionStorage.getItem("permission") == "true" &&
              ingredientId != "new" && (
                <>
                  <button
                    type="submit"
                    className="piece-content-publish"
                    disabled={
                      canSaveForm(formik) ||
                      JSON.stringify(ingredient) !=
                        JSON.stringify({ ...formik.values, upstreamIngredientSubcategoryId: undefined }) ||
                      configuration.path.produceSubcategoryState === 0
                    }
                  >
                    {ingredient.state === 0 ? "Publish" : "Unpublish"}
                  </button>
                </>
              )}
            <button
              className="piece-content-submit bg-primary-600"
              onClick={saveDraft}
              disabled={formik.isSubmitting}
            >
              {"Save"}
            </button>
            {JSON.stringify(ingredient) != JSON.stringify({ ...formik.values, upstreamIngredientSubcategoryId: undefined }) && (
              <b className="absolute text-sm bottom-0 text-rose-500">
                Please save before publishing new changes
              </b>
            )}
          </div>
        </form>
      </div>
      <ActivityRightPanel
        activityId={ingredientId}
        activityType={ActivityTypes.ProcessedIngredient}
        auditCreatedBy={ingredient.researcherName || ""}
        auditLastUpdatedBy={ingredient.lastUpdatedName}
        isVerified={formik.values.isVerified}
        verificationComments={formik.values.verificationComments}
        verifiedByName={formik.values.verifiedByName}
        verifiedOn={formik.values.verifiedOn}
        onChange={formik.handleChange}
        hideCompanyselect
      />
    </>
  ) : (
    <div className="overlay">
      <LottieLoader
        lottieType="butterflyLottie"
        style={{ height: "10.25rem", width: "10.25rem" }}
      />
    </div>
  );
};

export default ProcessedIngredient;
