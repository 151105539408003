import { FC, useEffect, useState, EffectCallback } from "react";
import { useAppDispatch } from "../../../hooks/redux";
import { useSearchParams, useNavigate } from "react-router-dom";
import {
  Icon,
  LottieLoader,
  Notification,
  Button,
  Breadcrumbs,
} from "@mondra/ui-components";
import { useAppSelector } from "../../../hooks/redux";
import { useFormik } from "formik";
import { fetchPiece, getConfiguration } from "./pieceActions";
import { sortDropdown, transformFormValues } from "../../../shared/formFunctions";
import {
  updatePackagingPiece,
  publishPackagingPiece,
  unPublishPackagingPiece,
  createPackagingPiece,
} from "./api";

import { usePrompt } from "../../../hooks/usePrompt";

import {
  handlePublishStatus,
  setDefaultPiece,
} from "../../../redux/packagingSlice";

import InputItem from "../../../components/InputItem";
import Collapsible from "react-collapsible";
import { IComponent } from "./types";
import ComponentForm from "./components/ComponentForm";
import { filterUnknown, formSchema } from "./utils";
import { addLocalFormNote, addLocalPopUpNote } from "../../../shared/noteFunctions";
import { showCorrelationIdError } from "../../../redux/correlationIdSlice";
import "./index.scss";
import ActivityRightPanel from "../../../components/ActivityRightPanel";
import { ActivityTypes } from "../../../const";

const Piece: FC = ({}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [showError, setShowError] = useState<string>("");
  const [errors, setErrors] = useState<[any, string[]][]>();
  const [showPublished, setShowPublished] = useState<boolean>(false);
  const { loading, piece, configuration } = useAppSelector(
    (state) => state.packagingSlice
  );
  const { loading: companiesLoading } = useAppSelector(
    (state) => state.companiesSlice
  );
  let pieceId = searchParams.get("itemId") || "";
  const isNew = pieceId === "new"
  useEffect((): ReturnType<EffectCallback> => {
    if(!companiesLoading){
      if (pieceId == "") {
        navigate("/not-found");
      }
      dispatch(getConfiguration());
      if (!isNew) {
        dispatch(fetchPiece(pieceId, () => navigate("/package-pieces")));
      }
      return () => {
        dispatch(setDefaultPiece());
      };
    }
  }, [companiesLoading, isNew]);

  useEffect(() => {
    formik.setValues({ ...piece });
  }, [piece]);

  const formik = useFormik({
    initialValues: {
      ...piece,
    },
    validationSchema: formSchema,
    onSubmit: async () => {
      if (piece.state === 0) {
        publishPackagingPiece(pieceId)
          .then(() => {
            dispatch(handlePublishStatus(1));
            setShowPublished(true);
            setTimeout(() => {
              setShowPublished(false);
            }, 4000);
          })
          .catch((error) =>
            setShowError(error.response.data.title)
          );
      } else {
        unPublishPackagingPiece(pieceId)
          .then(() => {
            dispatch(handlePublishStatus(0));
            setShowPublished(true);
            setTimeout(() => {
              setShowPublished(false);
            }, 4000);
          })
          .catch((error) =>
            setShowError(error.response.data.title)
          );
      }
    },
  });

  const saveDraft = async (e) => {
    e.preventDefault();
    const {values} = formik;

    const tValues = transformFormValues(values)
    
    for (let i = 0; i < tValues.componentsDetails.length; i++) {
      if (typeof tValues.componentsDetails[i].componentName === "string") {
        tValues.componentsDetails[i].componentName =
          +tValues.componentsDetails[i].componentName;
      }
      if (typeof tValues.componentsDetails[i].materialId === "string") {
        tValues.componentsDetails[i].materialId =
          +tValues.componentsDetails[i].materialId;
      }
      if (typeof tValues.componentsDetails[i].processingTypeId === "string") {
        tValues.componentsDetails[i].processingTypeId =
          +tValues.componentsDetails[i].processingTypeId;
      }
    }
    if (isNew) {
      try {
        await createPackagingPiece(tValues).then(() => {
          navigate("/package-pieces");
        });
      } catch (error: any) {
        if (error.showCorrelationId) {
          dispatch(showCorrelationIdError(error.CorrelationId));
        } else {
          setShowError(
            typeof error.response.data === "string"
              ? error.response.data
              : typeof error.response.data.errors === "string"
              ? error.response.data.errors
              : Object.values(error.response.data.errors)
                  .toString()
                  .replace(/!,/g, "!\n")
          );
          setTimeout(() => {
            setShowError("");
          }, 5000);
          setErrors(
            typeof error.response.data.errors === "string"
              ? error.response.data.errors
              : Object.entries(error.response.data.errors)
          );
        }
      }
    } else {
      try {
        await updatePackagingPiece(pieceId, tValues)
          .then(() =>
            dispatch(fetchPiece(pieceId, () => navigate("/package-pieces")))
          )
          .then(() => {
            setShowNotification(true);
            setTimeout(() => {
              setShowNotification(false);
            }, 5000);
          });
      } catch (error: any) {
        if (error.showCorrelationId) {
          dispatch(showCorrelationIdError(error.CorrelationId));
        } else {
          setShowError(
            typeof error.response.data === "string"
              ? error.response.data
              : typeof error.response.data.errors === "string"
              ? error.response.data.errors
              : Object.values(error.response.data.errors)
                  .toString()
                  .replace(/!,/g, "!\n")
          );
          setTimeout(() => {
            setShowError("");
          }, 5000);
          setErrors(
            typeof error.response.data.errors === "string"
              ? error.response.data.errors
              : Object.entries(error.response.data.errors)
          );
        }
      }
    }
  }

  usePrompt(
    "There are unsaved changes, do you want to leave?",
    pieceId !== "new"
      ? JSON.stringify(piece) != JSON.stringify(formik.values)
      : false
  );

  const addComponent = (values) => {
    formik.setValues({
      ...formik.values,
      componentsDetails: [...formik.values.componentsDetails, values],
    });
  };

  const deleteComponent = (idx) => {
    const newComponents = formik.values.componentsDetails.filter(
      (el, index) => index != +idx
    );
    formik.setValues({
      ...formik.values,
      componentsDetails: newComponents,
    });
  };

  return loading !== true || isNew? (
    <>
      <div className="piece content">
        {showError !== "" && (
          <div className={"piece-notification"}>
            <Notification type="error" label="Oops:" description={showError} />
          </div>
        )}
        {showPublished && (
          <div className={"piece-notification"}>
            <Notification
              type="success"
              label="Success:"
              description="Status was updated!"
              isToast={false}
            />
          </div>
        )}
        {showNotification && (
          <div className={"piece-notification"}>
            <Notification
              type="success"
              label="Success:"
              description="Item was updated!"
              isToast={false}
            />
          </div>
        )}
        <div className="mt-3 mb-3">
          <Breadcrumbs
            routes={[
              { path: "/package-pieces", title: "Packaging Table" },
              {
                path: `/package-pieces/piece?itemId=${pieceId}`,
                title: "Packaging Piece Item",
              },
            ]}
          />
        </div>
        {!isNew && (
          <div className="piece-header">
            <h2 className={"text-2xl"}>{piece.pieceName}</h2>
            <div
              className={`piece-header-status ${
                piece.state === 0 ? "bg-red-500" : "bg-emerald-500"
              }`}
            >
              {piece.state === 0 ? "Not Published" : "Published"}
            </div>
            {piece?.lastPublishedDate ? (
                <span className={"inputItem-header"}>
                  {`Last published date: ${new Date(
                    piece.lastPublishedDate
                  ).toLocaleString()}
                  `}
                </span>
              ) : (
                ""
              )}
          </div>
        )}
        <p className={"decoration-gray-600 mt-1 mb-7 piece-description"}>
          This database is a collation of packaging pieces and impacts
          associated with their production. The database gives detailed
          information on materials, production processes, capacity and weights
          of individual packaging pieces. Impacts associated with production of
          a packaging piece is for 1 item.
        </p>

        <form onSubmit={formik.handleSubmit} className="piece-content">
          <h5 className={"mt-5"}>Inputs</h5>
          <div className="piece-content-inputs">
            <InputItem
              name="Packaging Piece sourced from RER/RoW"
              description="Select location"
              type="select"
              onChange={formik.handleChange}
              defaultValue={formik.values.sourcedFromRERRoW}
              formikName="sourcedFromRERRoW"
              formik={formik}
              showAsterisk={true}
              options={sortDropdown(
                configuration.regions,
                formik.values.sourcedFromRERRoW,
                "name"
              )}
            />
            <InputItem
              name="Typical uses"
              description="Select"
              type="select"
              defaultValue={formik.values.typicalUses}
              onChange={formik.handleChange}
              formikName="typicalUses"
              formik={formik}
              showAsterisk
              options={filterUnknown(sortDropdown(
                configuration.typicalUses,
                formik.values.typicalUses,
                "description"
              ), formik.values.code)}
            />
            <InputItem
              name="Packaging type"
              description="Choose a packaging type"
              type="select"
              formik={formik}
              defaultValue={formik.values.packagingType}
              onChange={formik.handleChange}
              formikName="packagingType"
              showAsterisk
              options={sortDropdown(
                configuration.packagingType,
                formik.values.packagingType,
                "description"
              )}
            />
            <InputItem
              name="Packaging piece group"
              description="Choose packaging piece group"
              type="select"
              defaultValue={formik.values.pieceGroup}
              onChange={formik.handleChange}
              formikName="pieceGroup"
              formik={formik}
              showAsterisk={true}
              options={filterUnknown(sortDropdown(
                configuration.pieceGroup,
                formik.values.pieceGroup,
                "description"
              ), formik.values.code)}
            />
            <InputItem
              name="Packaging Piece Name"
              description="Please enter a packaging piece name"
              type="input"
              formik={formik}
              onChange={formik.handleChange}
              showAsterisk={true}
              defaultValue={formik.values.pieceName}
              formikName="pieceName"
            />
            <InputItem
              type="input"
              name={"Click up ID"}
              description="Please provide the URL to the click-up task "
              formik={formik}
              defaultValue={formik.values.clickUpId || ""}
              onChange={formik.handleChange}
              formikName="clickUpId"
            />
            <InputItem
              name="Piece total mass (empty), kg"
              description="Please enter the total empty mass of the packing piece in kg"
              type="input"
              addonTitle="kg"
              formik={formik}
              showAsterisk={true}
              onChange={formik.handleChange}
              defaultValue={
                formik.values.totalMass === null ? "" : formik.values.totalMass
              }
              formikName="totalMass"
              addLocal={(params) =>
                addLocalPopUpNote({
                  formik,
                  index: formik.values.totalMassNotes?.length,
                  ...params,
                })
              }
              notes={formik.values.totalMassNotes || []}
            />
            <InputItem
              name="Maximum loading capacity"
              description="Please enter the maximum loading capacity in kg"
              type="input"
              addonTitle="kg"
              onChange={formik.handleChange}
              formik={formik}
              showAsterisk={true}
              defaultValue={
                formik.values.maximumLoadingCapacity === null
                  ? ""
                  : formik.values.maximumLoadingCapacity
              }
              formikName="maximumLoadingCapacity"
              addLocal={(params) =>
                addLocalPopUpNote({
                  formik,
                  index: formik.values.maximumLoadingCapacityNotes?.length,
                  ...params,
                })
              }
              notes={formik.values.maximumLoadingCapacityNotes || []}
            />
            <InputItem
              name="Length"
              description="Length in mm"
              type="input"
              addonTitle="mm"
              formik={formik}
              onChange={formik.handleChange}
              defaultValue={
                formik.values.length === null ? "" : formik.values.length
              }
              formikName="length"
            />
            <InputItem
              name="Width"
              description="Width in mm"
              type="input"
              addonTitle="mm"
              formik={formik}
              onChange={formik.handleChange}
              defaultValue={
                formik.values.width === null ? "" : formik.values.width
              }
              formikName="width"
            />
            <InputItem
              name="Height"
              description="Height in mm"
              type="input"
              addonTitle="mm"
              formik={formik}
              onChange={formik.handleChange}
              defaultValue={
                formik.values.height === null ? "" : formik.values.height
              }
              formikName="height"
            />
            <InputItem
              name="Number of uses"
              description="Please enter how often the packaging type is used. If it’s a single use item, please enter 1"
              type="input"
              formik={formik}
              onChange={formik.handleChange}
              showAsterisk={true}
              defaultValue={
                formik.values.numberOfUses === null
                  ? ""
                  : formik.values.numberOfUses
              }
              addLocal={(params) =>
                addLocalPopUpNote({
                  formik,
                  index: formik.values.numberOfUsesNotes?.length,
                  ...params,
                })
              }
              notes={formik.values.numberOfUsesNotes || []}
              formikName="numberOfUses"
            />
            <InputItem
              name="Packaging material loss"
              description="Please enter the packaging material loss in kg"
              type="input"
              addonTitle="kg"
              formik={formik}
              onChange={formik.handleChange}
              defaultValue={formik.values.packagingMaterialLoss || ""}
              formikName="packagingMaterialLoss"
              addLocal={(params) =>
                addLocalPopUpNote({
                  formik,
                  index: formik.values.packagingMaterialLossNotes?.length,
                  ...params,
                })
              }
              notes={formik.values.packagingMaterialLossNotes || []}
            />
            <InputItem
              name="External code"
              description=""
              disabled
              type="input"
              formik={formik}
              onChange={formik.handleChange}
              defaultValue={formik.values.code || ""}
              formikName="code"
            />
            <InputItem
              name="Number of components"
              description=""
              type="input"
              disabled={true}
              formik={formik}
              defaultValue={formik.values.componentsDetails.length}
            />
          </div>
          <div className={"piece-content-collapsible"}>
            <h5 className={"mt-5"}>Component Details</h5>
            {formik.values.componentsDetails.length === 0 && (
              <span className="text-rose-600">
                You should have at least one component
              </span>
            )}
            <br />
            {formik.values.componentsDetails &&
              formik.values.componentsDetails.map(
                (component: IComponent, index: number) => {
                  return (
                    <Collapsible
                      key={index + component.id}
                      trigger={
                        <div className="collapsible_trigger">
                          <span>
                            {
                              configuration.componentName.find(
                                (el) => el.id === +component.componentName
                              )?.description
                            }
                          </span>
                          <Icon
                            className="collapsible_icon"
                            type="chevronDown"
                          />
                        </div>
                      }
                    >
                      <div className="piece-content-inputs">
                        <InputItem
                          name="Component name"
                          description="Select your component name"
                          type="select"
                          formik={formik}
                          defaultValue={component.componentName}
                          onChange={formik.handleChange}
                          formikName={`componentsDetails[${index}].componentName`}
                          options={sortDropdown(
                            configuration.componentName,
                            component.componentName,
                            "description"
                          )}
                          index={index}
                        />
                        <InputItem
                          name="Material"
                          description="Select your component material"
                          type="select"
                          formik={formik}
                          defaultValue={component.materialId}
                          onChange={formik.handleChange}
                          formikName={`componentsDetails[${index}].materialId`}
                          notes={component.materialIdNotes || []}
                          addLocal={(params) =>
                            addLocalFormNote({
                              formik,
                              arrayName: "componentsDetails",
                              componentIndex: index,
                              index: component.materialIdNotes?.length,
                              ...params,
                            })
                          }
                          options={sortDropdown(
                            configuration.materials,
                            component.materialId,
                            "processName"
                          )}
                          index={index}
                        />
                        <InputItem
                          name="Processing type"
                          description="Select your processing type"
                          type="select"
                          formik={formik}
                          defaultValue={component.processingTypeId}
                          onChange={formik.handleChange}
                          formikName={`componentsDetails[${index}].processingTypeId`}
                          options={sortDropdown(
                            configuration.processes,
                            component.processingTypeId,
                            "processName"
                          )}
                          index={index}
                          notes={component.processingTypeIdNotes || []}
                          addLocal={(params) =>
                            addLocalFormNote({
                              formik,
                              arrayName: "componentsDetails",
                              componentIndex: index,
                              index: component.processingTypeIdNotes?.length,
                              ...params,
                            })
                          }
                        />
                        <InputItem
                          name="Component Mass (empty weight) "
                          description="Mass of this component"
                          type="input"
                          addonTitle="kg"
                          formik={formik}
                          onChange={(e) => {
                            formik.setFieldTouched(
                              `componentsDetails[${index}].componentMass`
                            );
                            formik.handleChange(e);
                          }}
                          defaultValue={component.componentMass}
                          formikName={`componentsDetails[${index}].componentMass`}
                          index={index}
                        />
                        <InputItem
                          name="Material composition"
                          description="Enter percentage"
                          type="input"
                          addonTitle="%"
                          formik={formik}
                          onChange={(e) => {
                            formik.setFieldTouched(
                              `componentsDetails[${index}].materialComposition`
                            );
                            formik.handleChange(e);
                          }}
                          defaultValue={component.materialComposition}
                          formikName={`componentsDetails[${index}].materialComposition`}
                          index={index}
                        />
                        <InputItem
                          name="Recycled content"
                          description="Enter percentage"
                          type="input"
                          addonTitle="%"
                          formik={formik}
                          onChange={(e) => {
                            formik.setFieldTouched(
                              `componentsDetails[${index}].recycledContent`
                            );
                            formik.handleChange(e);
                          }}
                          defaultValue={component.recycledContent}
                          formikName={`componentsDetails[${index}].recycledContent`}
                          notes={component.recycledContentNotes || []}
                          addLocal={(params) =>
                            addLocalFormNote({
                              formik,
                              arrayName: "componentsDetails",
                              componentIndex: index,
                              index: component.recycledContentNotes?.length,
                              ...params,
                            })
                          }
                          index={index}
                        />
                      </div>
                      <Button
                        className={"piece-component-delete"}
                        onClick={() => deleteComponent(index)}
                        variant="danger"
                        iconType="close"
                      >
                        Delete
                      </Button>
                    </Collapsible>
                  );
                }
              )}
            <ComponentForm
              addComponent={addComponent}
              code={formik.values.code}
              processes={configuration.processes}
              material={configuration.materials}
              componentName={configuration.componentName}
            />
          </div>
          <h5 className={"mt-5"}>Impacts</h5>
          <p>
            Please provide the environmental impact per 1 item of the total
            packaging piece, including all of its components.
          </p>
          <div className={"piece-content-inputs mb-5"}>
            <InputItem
              name="Carbon"
              description="Please provide carbon emissions in kg CO2 eq"
              type="input"
              addonTitle="kg"
              formik={formik}
              showAsterisk={true}
              onChange={formik.handleChange}
              defaultValue={
                formik.values.carbon
              }
              formikName="carbon"
            />
            <InputItem
              name="Water usage"
              description="Please provide water usage in m3"
              type="input"
              addonTitle="m3"
              formik={formik}
              showAsterisk={true}
              onChange={formik.handleChange}
              defaultValue={
                formik.values.waterUsage
              }
              formikName="waterUsage"
            />
            <InputItem
              name="Eutrophication"
              description="Please provide eutrophication in kg PO4^3- eq"
              type="input"
              addonTitle="kg"
              formik={formik}
              showAsterisk={true}
              onChange={formik.handleChange}
              defaultValue={
                formik.values.eutrophication
              }
              formikName="eutrophication"
            />
          </div>
          <InputItem
            name="Comment"
            description=""
            type="text"
            onChange={formik.handleChange}
            formik={formik}
            className={"w-full"}
            defaultValue={formik.values.comment || ""}
            formikName="comment"
          />
          <h5 className={"mt-5"}>Data Quality</h5>
          <div className={"piece-content-inputs mb-5"}>
            <InputItem
              name="Secondary Data Reference Source Type"
              description="If multiple references sources where used to generate this record please indicate the overall reference source type by select the reference where the majority of the data was retrieved from, if this is not possible please select the source type with the lowest ranking. If this is not possible take the reference which was published dating back the longest. Please note “Peer-reviewed published literature” can be a journal, conference publication of book chapter."
              type="select"
              defaultValue={formik.values.secondaryDataReferenceSourceType}
              onChange={formik.handleChange}
              formik={formik}
              formikName="secondaryDataReferenceSourceType"
              options={sortDropdown(
                configuration.secondaryDataReferenceSourceType,
                piece.secondaryDataReferenceSourceType ?? -1,
                "description"
              )}
            />
            <InputItem
              disabled
              name="Data quality reliability score"
              description="Read only"
              type="input"
              formik={formik}
              defaultValue={formik.values.dataQualityReliabilityScore || ""}
              formikName="dataQualityReliabilityScore"
            />
            <InputItem
              name="References Sampling Year"
              description="The year in which most of the data was collected. If this is a time frame please choose the end date. If the data was equally collected from multiple studies please select the sourcing year from the study published the furthest in the past."
              type="input"
              onChange={formik.handleChange}
              formik={formik}
              defaultValue={formik.values.referencesSamplingYear || ""}
              formikName="referencesSamplingYear"
            />
            <InputItem
              name="Reference Data Publication Year"
              description="The year in which most of the data was published. If the data was equally collected from multiple studies please select the publication year from the study published the furthest in the past. "
              type="input"
              onChange={formik.handleChange}
              formik={formik}
              defaultValue={formik.values.referenceDataPublicationYear || ""}
              formikName="referenceDataPublicationYear"
            />
            <InputItem
              name="Reference Sourcing Country"
              description="Please select the Country Name identifier in which the data was collected (not published). Please select the country where most of the data was collected from. Only use publication country if no other information is available."
              type="select"
              defaultValue={
                formik.values.referenceSourcingCountry
                  ? formik.values.referenceSourcingCountry
                  : -1
              }
              onChange={formik.handleChange}
              formik={formik}
              formikName="referenceSourcingCountry"
              options={sortDropdown(
                configuration.countries,
                formik.values.referenceSourcingCountry ?? -1,
                "countryName"
              )}
            />
            <InputItem
              name="Reference Country ISO Code"
              description="Please select the Country Name identifier in which the data was collected (not published). Please select the country which most of the data was collected from. Only use publication country if no other information is available."
              type="select"
              defaultValue={formik.values.referenceSourcingCountry || -1}
              onChange={formik.handleChange}
              formik={formik}
              formikName="referenceSourcingCountry"
              options={sortDropdown(
                configuration.countries,
                formik.values.referenceSourcingCountry ?? -1,
                "countryISO"
              )}
            />
            <InputItem
              name="Reference Region Identifier"
              description="The region identifier in which the data was collected. Please select the region where most of the data was collected from."
              type="select"
              onChange={formik.handleChange}
              formikName="referenceRegionIdentifier"
              defaultValue={
                formik.values.referenceRegionIdentifier
                  ? formik.values.referenceRegionIdentifier
                  : -1
              }
              formik={formik}
              options={sortDropdown(
                configuration.regions,
                formik.values.referenceRegionIdentifier || 0,
                "name"
              )}
            />
          </div>
          <InputItem
            name="Data Quality Comment"
            description="Please provide any comments on assumption that were made to
              determine the criteria for data quality for this record taking
              multiple references into account."
            type="text"
            onChange={formik.handleChange}
            formik={formik}
            className={"w-full"}
            defaultValue={formik.values.dataQualityComment || ""}
            formikName="dataQualityComment"
          />
          {errors &&
            (Array.isArray(errors) ? (
              <ul className="piece-errors">
                {errors.map(([key, value]) =>
                  value.map((err) => (
                    <li>
                      <b>{key}:</b> {err}
                    </li>
                  ))
                )}
              </ul>
            ) : (
              <ul className="piece-errors">
                <li>{errors}</li>
              </ul>
            ))}
          <div className="piece-actions">
            {sessionStorage.getItem("permission") == "true" &&
              pieceId != "new" && (
                <>
                  {/* <span
                onClick={() => setShowDelete(true)}
                className="piece-content-publish"
              >
                Delete
              </span> */}
                  <button
                    type="submit"
                    className="piece-content-publish"
                    disabled={
                      formik.values.componentsDetails.length === 0 ||
                      JSON.stringify(piece) != JSON.stringify(formik.values)
                    }
                  >
                    {piece.state === 0 ? "Publish" : "Unpublish"}
                  </button>
                </>
              )}
            <button
              className="piece-content-submit bg-primary-600"
              onClick={saveDraft}
              disabled={
                formik.isSubmitting
              }
            >
              Save
            </button>
            {JSON.stringify(piece) != JSON.stringify(formik.values) && (
              <b className="absolute text-sm bottom-0 text-rose-500">
                Please save before publishing new changes
              </b>
            )}
          </div>
        </form>
      </div>
      <ActivityRightPanel
        activityId={pieceId}
        activityType={ActivityTypes.PackagingPiece}
        auditCreatedBy={piece.researcherName}
        auditLastUpdatedBy={piece.lastUpdatedName}
        companyId={formik.values.companyId}
        isVerified={formik.values.isVerified}
        verificationComments={formik.values.verificationComments}
        verifiedByName={formik.values.verifiedByName}
        verifiedOn={formik.values.verifiedOn}
        onChange={formik.handleChange}
      />
    </>
  ) : (
    <div className="overlay">
      <LottieLoader
        lottieType="butterflyLottie"
        style={{ height: "10.25rem", width: "10.25rem" }}
      />
    </div>
  );
};

export default Piece;
