import { FC, useEffect, useState, EffectCallback } from "react";
import { useAppDispatch } from "../../../hooks/redux";
import { useSearchParams, useNavigate } from "react-router-dom";
import {
  Breadcrumbs,
  LottieLoader,
  Notification,
} from "@mondra/ui-components";
import { useAppSelector } from "../../../hooks/redux";
import { useFormik } from "formik";
import { fetchResources, getConfiguration } from "./resourcesActions";
import { sortDropdown, sortResourcesDropdown, transformFormValues } from "../../../shared/formFunctions";
import { formSchema } from "./utils";
import {
  updateResourceSource,
  publishResourceSource,
  unPublishResourceSource,
  createResourceSource,
} from "./api";

import { usePrompt } from "../../../hooks/usePrompt";

import {
  handlePublishStatus,
  setDefaultResource,
} from "../../../redux/resourcesSlice";

import InputItem from "../../../components/InputItem";
import { showCorrelationIdError } from "../../../redux/correlationIdSlice";
import "./index.scss";
import ActivityRightPanel from "../../../components/ActivityRightPanel";
import { ActivityTypes } from "../../../const";

const Resource: FC = ({}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const { loading, resource, configuration } = useAppSelector(
    (state) => state.resourcesSlice
  );
  const { loading: companiesLoading } = useAppSelector(
    (state) => state.companiesSlice
  );
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [showError, setShowError] = useState<string>("");
  const [errors, setErrors] = useState<[any, string[]][]>();
  const [showPublished, setShowPublished] = useState<boolean>(false);
  let resourceId = searchParams.get("itemId") || "";
  const isNew = resourceId === "new"

  useEffect((): ReturnType<EffectCallback> => {
    if(!companiesLoading){
      if (resourceId == "") {
        navigate("/not-found");
      }
      if (isNew) {
        dispatch(getConfiguration("0"));
      } else {
        dispatch(getConfiguration(resourceId));
      }
      if (!isNew) {
        dispatch(
          fetchResources(resourceId, () => navigate("/resources-and-sources"))
        );
      }
      return () => {
        dispatch(setDefaultResource());
      };
    }
  }, [companiesLoading, isNew]);

  useEffect(() => {
    formik.setValues({ 
      ...resource, 
      resourceName: 
        formik.values.resourceName
          ? formik.values.resourceName 
          : configuration.resourceNameForUpdate
          ? configuration.resourceNameForUpdate
          : "",
      });
  }, [resource]);

  const formik = useFormik({
    initialValues: {
      ...resource,
      resourceName: 
      resource.resourceName 
        ? resource.resourceName
        : configuration.resourceNameForUpdate
        ? configuration.resourceNameForUpdate
        : "",
    },
    validationSchema: formSchema,
    onSubmit: async () => {
      if (resource.state === 0) {
        publishResourceSource(resourceId)
          .then(() => {
            dispatch(handlePublishStatus(1));
            setShowPublished(true);
            setTimeout(() => {
              setShowPublished(false);
            }, 4000);
          })
          .catch((error) =>
            setShowError(error.response.data.title)
          );
      } else {
        unPublishResourceSource(resourceId)
          .then(() => {
            dispatch(handlePublishStatus(0));
            setShowPublished(true);
            setTimeout(() => {
              setShowPublished(false);
            }, 4000);
          })
          .catch((error) =>
            setShowError(error.response.data.title)
          );
      }
    },
  });

  const saveDraft = async (e) => {
    e.preventDefault();
    const {values} = formik;

    const tValues = transformFormValues(values)
    
    if (resourceId === "new") {
      try {
        await createResourceSource(tValues).then(() => {
          navigate("/resources-and-sources");
        });
      } catch (error: any) {
        if (error.showCorrelationId) {
          dispatch(showCorrelationIdError(error.CorrelationId));
        } else {
          setShowError(
            typeof error.response.data === "string" 
              ? error.response.data
              : typeof error.response.data.errors === "string"
              ? error.response.data.errors
              : Object.values(error.response.data.errors).toString().replace(/!,/g, '!\n')
          );
          setTimeout(() => {
            setShowError("");
          }, 5000);
          setErrors(
            typeof error.response.data.errors === "string" 
              ? error.response.data.errors
              : Object.entries(error.response.data.errors)
          );
        }
      }
    } else {
      try {
        await updateResourceSource(resourceId, tValues)
          .then(() =>
            dispatch(
              fetchResources(resourceId, () =>
                navigate("/resources-and-sources")
              )
            )
          )
          .then(() => {
            setShowNotification(true);
            setTimeout(() => {
              setShowNotification(false);
            }, 5000);
          });
      } catch (error: any) {
        if (error.showCorrelationId) {
          dispatch(showCorrelationIdError(error.CorrelationId));
        } else {
          setShowError(
            typeof error.response.data === "string" 
              ? error.response.data
              : typeof error.response.data.errors === "string"
              ? error.response.data.errors
              : Object.values(error.response.data.errors).toString().replace(/!,/g, '!\n')
          );
          setTimeout(() => {
            setShowError("");
          }, 5000);
          setErrors(
            typeof error.response.data.errors === "string" 
              ? error.response.data.errors
              : Object.entries(error.response.data.errors)
          );
        }
      }
    }
  };

  useEffect(() => {
    formik.setFieldValue(
      "resourceName",
      formik.values.resourceName
        ? formik.values.resourceName
        : configuration.resourceNameForUpdate
    );
  }, [configuration.resourceNameForUpdate]);


  usePrompt(
    "There are unsaved changes, do you want to leave?",
    !isNew
      ? JSON.stringify({...resource, resourceName: formik.values.resourceName}) != JSON.stringify(formik.values)
      : false
  );

  return loading !== true || isNew ? (
    <>
      <div className="piece content">
        {showError !== "" && (
          <div className={"piece-notification"}>
            <Notification type="error" label="Oops:" description={showError} />
          </div>
        )}
        {showPublished && (
          <div className={"piece-notification"}>
            <Notification
              type="success"
              label="Success:"
              description="Status was updated!"
              isToast={false}
            />
          </div>
        )}
        {showNotification && (
          <div className={"piece-notification"}>
            <Notification
              type="success"
              label="Success:"
              description="Item was updated!"
              isToast={false}
            />
          </div>
        )}
        <div className="mt-3 mb-3">
          <Breadcrumbs
            routes={[
              {
                path: "/resources-and-sources",
                title: "Resources and Sources Table",
              },
              {
                path: `/resources-and-sources/resource?itemId=${resourceId}`,
                title: "Resource/Source",
              },
            ]}
          />
        </div>
        {resourceId !== "new" && (
          <>
            <div className="piece-header">
              <div
                className={`piece-header-status ${
                  resource.state === 0 ? "bg-red-500" : "bg-emerald-500"
                }`}
              >
                {resource.state === 0 ? "Not Published" : "Published"}
              </div>
              {resource?.lastPublishedDate ? (
                <span className={"inputItem-header"}>
                  {`Last published date: ${new Date(
                    resource.lastPublishedDate
                  ).toLocaleString()}
                  `}
                </span>
              ) : (
                ""
              )}
            </div>
          </>
        )}

        <p className={"decoration-gray-600 mt-1 mb-7 piece-description"}>
          This database is a collation of various resource, their source types,
          and associated impacts. All resource, source, and impacts data has
          been reported for a functional unit of <b>1 unit of the resource</b>.
        </p>
        <form onSubmit={formik.handleSubmit} className="piece-content">
          <h5 className={"mt-5"}>Inputs</h5>
          <div className="piece-content-inputs">
            <InputItem
              name="Resource Name"
              description="Choose a resource name"
              type="select"
              formik={formik}
              showAsterisk
              defaultValue={formik.values.resourceName}
              onChange={(e: any) => {
                !isNew &&
                  formik.setFieldValue(
                    "resourceSourceId",
                    sortDropdown(
                      configuration.resourcesSources[e.target.value],
                      resource.resourceSourceId,
                      "sourceName"
                    )?.[0].value
                  );
                formik.handleChange(e);
              }}
              formikName="resourceName"
              options={sortResourcesDropdown(
                configuration.resourcesSources,
                formik.values.resourceName || configuration.resourceNameForUpdate,
              )}
            />
            <InputItem
              name="Source Name"
              description="Choose a source name"
              type="select"
              formik={formik}
              showAsterisk
              onChange={formik.handleChange}
              formikName="resourceSourceId"
              defaultValue={formik.values.resourceSourceId}
              options={sortDropdown(
                configuration.resourcesSources[
                  formik.values.resourceName 
                    ? formik.values.resourceName 
                    : configuration.resourceNameForUpdate
                    ? configuration.resourceNameForUpdate
                    : ""
                  ],
                resource.resourceSourceId,
                'sourceName'
              )}
            />
            <InputItem
              name="Country name"
              description="Select country"
              type="select"
              onChange={formik.handleChange}
              formikName="countryId"
              showAsterisk
              defaultValue={
                formik.values.countryId 
                  ? formik.values.countryId 
                  : -1
              }
              formik={formik}
              options={sortDropdown(
                configuration.countries,
                formik.values.countryId ?? -1,
                "countryName"
              )}
            />
            <InputItem
              name="Country ISO Code"
              description="Please select the country in which the data was collected."
              type="select"
              defaultValue={
                formik.values.countryId 
                  ? formik.values.countryId 
                  : -1
              }
              onChange={formik.handleChange}
              formik={formik}
              showAsterisk
              formikName="countryId"
              options={sortDropdown(
                configuration.countries,
                formik.values.countryId ?? -1,
                "countryISO"
              )}
            />
            <InputItem
              name="Region Identifier"
              description="Select location"
              type="select"
              defaultValue={formik.values.regionIdentifierId}
              onChange={formik.handleChange}
              formikName="regionIdentifierId"
              formik={formik}
              showAsterisk
              options={sortDropdown(
                configuration.regions,
                resource.regionIdentifierId,
                "name"
              )}
            />
            <InputItem
              name="Ref. Quantity"
              description="Default value is 1!"
              type="input"
              addonTitle={
                configuration.units.find(
                  (el) =>
                    el.id ===
                    configuration.resourcesSources[
                      formik.values.resourceName 
                      ? formik.values.resourceName 
                      : configuration.resourceNameForUpdate 
                      ? configuration.resourceNameForUpdate 
                      : ""
                    ]?.[0].resourceUnit
                )?.description
              }
              onChange={formik.handleChange}
              formik={formik}
              showAsterisk
              defaultValue={formik.values.referenceQuantity}
              formikName="refQuantity"
            />
            <InputItem
              name="Resource-Source Description"
              description="please give a short description on the type of resource source that is being uploaded"
              type="input"
              onChange={formik.handleChange}
              formik={formik}
              defaultValue={formik.values.description || ""}
              formikName="description"
            />
            <InputItem
              name="Process Name"
              description="Process name from Ecoinvent or Agri-Footprint"
              type="input"
              onChange={formik.handleChange}
              formik={formik}
              defaultValue={formik.values.processName || ""}
              formikName="processName"
            />
            <InputItem
              name="Comment"
              description="Please provide any information on how the process from Ecoinvent or Agri-Footprint was modified"
              type="input"
              onChange={formik.handleChange}
              formik={formik}
              defaultValue={formik.values.comment || ""}
              formikName="comment"
            />
            <InputItem
              name="External code"
              description=""
              disabled
              type="input"
              formik={formik}
              onChange={formik.handleChange}
              defaultValue={formik.values.code || ""}
              formikName="code"
            />
          </div>
          <h5 className={"mt-5"}>Impacts</h5>
          <p>
            Please provide impacts for 1 unit of the specified resource-source.
          </p>
          <div className={"piece-content-inputs"}>
            <InputItem
              name="Carbon"
              description="Please provide carbon emissions in kg CO2 eq"
              type="input"
              addonTitle="kg"
              formik={formik}
              showAsterisk
              onChange={formik.handleChange}
              defaultValue={
                formik.values.carbon === 0 ? "0" : formik.values.carbon
              }
              formikName="carbon"
            />
            <InputItem
              name="Water usage"
              description="Please provide water usage in m3"
              type="input"
              addonTitle="m3"
              formik={formik}
              showAsterisk
              onChange={formik.handleChange}
              defaultValue={
                formik.values.waterUsage === 0 ? "0" : formik.values.waterUsage
              }
              formikName="waterUsage"
            />
            <InputItem
              name="Eutrophication"
              description="Please provide eutrophication in  kg PO4^3- eq"
              type="input"
              addonTitle="kg"
              formik={formik}
              showAsterisk
              onChange={formik.handleChange}
              defaultValue={
                formik.values.eutrophication === 0
                  ? "0"
                  : formik.values.eutrophication
              }
              formikName="eutrophication"
            />
          </div>
          <h5 className={"mt-5"}>Data Quality</h5>
          <div className={"piece-content-inputs mb-5"}>
            <InputItem
              name="Secondary Data Reference Source Type"
              description="If multiple references sources where used to generate this record please indicate the overall reference source type by select the reference where the majority of the data was retrieved from, if this is not possible please select the source type with the lowest ranking. If this is not possible take the reference which was published dating back the longest. Please note “Peer-reviewed published literature” can be a journal, conference publication of book chapter."
              type="select"
              defaultValue={formik.values.secondaryDataReferenceSourceType}
              onChange={formik.handleChange}
              formik={formik}
              showAsterisk
              formikName="secondaryDataReferenceSourceType"
              options={sortDropdown(
                configuration.secondaryDataReferenceSourceType,
                resource.secondaryDataReferenceSourceType,
                "description"
              )}
            />
            <InputItem
              disabled
              name="Data quality reliability score"
              description="Read only"
              type="input"
              formik={formik}
              defaultValue={formik.values.dataQualityReliabilityScore || ""}
              formikName="dataQualityReliabilityScore"
            />
            <InputItem
              name="References Sampling Year"
              description="The year in which most of the data was collected. If this is a time frame please choose the end date. If the data was equally collected from multiple studies please select the sourcing year from the study published the furthest in the past."
              type="input"
              onChange={formik.handleChange}
              formik={formik}
              defaultValue={formik.values.referencesSamplingYear || ""}
              formikName="referencesSamplingYear"
            />
            <InputItem
              name="Reference Data Publication Year"
              description="The year in which most of the data was published. If the data was equally collected from multiple studies please select the publication year from the study published the furthest in the past. "
              type="input"
              onChange={formik.handleChange}
              formik={formik}
              showAsterisk
              defaultValue={
                formik.values.referenceDataPublicationYear === 0
                  ? ""
                  : formik.values.referenceDataPublicationYear
              }
              formikName="referenceDataPublicationYear"
            />
            <InputItem
              name="Reference Sourcing Country"
              description="Please select the Country Name identifier in which the data was collected (not published). Please select the country where most of the data was collected from. Only use publication country if no other information is available."
              type="select"
              defaultValue={
                formik.values.referenceSourcingCountry
                  ? formik.values.referenceSourcingCountry 
                  : -1
              }
              onChange={formik.handleChange}
              formik={formik}
              formikName="referenceSourcingCountry"
              options={sortDropdown(
                configuration.countries,
                formik.values.referenceSourcingCountry ?? -1,
                "countryName"
              )}
            />
            <InputItem
              name="Reference Country ISO Code"
              description="Please select the Country Name identifier in which the data was collected (not published). Please select the country which most of the data was collected from. Only use publication country if no other information is available."
              type="select"
              defaultValue={
                formik.values.referenceSourcingCountry 
                  ? formik.values.referenceSourcingCountry 
                  : -1
              }
              onChange={formik.handleChange}
              formik={formik}
              formikName="referenceSourcingCountry"
              options={sortDropdown(
                configuration.countries,
                formik.values.referenceSourcingCountry ?? -1,
                "countryISO"
              )}
            />
            <InputItem
              name="Reference Region Identifier"
              description="The region identifier in which the data was collected. Please select the region where most of the data was collected from."
              type="select"
              defaultValue={formik.values.referenceRegionIdentifier}
              onChange={formik.handleChange}
              formikName="referenceRegionIdentifier"
              showAsterisk
              formik={formik}
              options={sortDropdown(
                configuration.regions,
                resource.referenceRegionIdentifier,
                "name"
              )}
            />
          </div>
          <InputItem
            name="Data Quality Comment"
            description="Please provide any comments on assumption that were made to
              determine the criteria for data quality for this record taking
              multiple references into account."
            type="text"
            onChange={formik.handleChange}
            formik={formik}
            className={"w-full"}
            defaultValue={formik.values.dataQualityComment || ""}
            formikName="dataQualityComment"
          />
          {errors &&
            (Array.isArray(errors) ? (
              <ul className="piece-errors">
                {errors.map(([key, value]) =>
                  value.map((err) => (
                    <li>
                      <b>{key}:</b> {err}
                    </li>
                  ))
                )}
              </ul>
            ) : (
              <ul className="piece-errors">
                <li>{errors}</li>
              </ul>
            ))}
          <div className="piece-actions">
            {sessionStorage.getItem("permission") == "true" &&
              resourceId != "new" && (
                <>
                  <button
                    type="submit"
                    className="piece-content-publish"
                    disabled={
                      JSON.stringify({...resource, resourceName: formik.values.resourceName}) != JSON.stringify(formik.values)
                    }
                  >
                    {resource.state === 0 ? "Publish" : "Unpublish"}
                  </button>
                </>
              )}
            <button
              className="piece-content-submit bg-primary-600"
              onClick={saveDraft}
              disabled={formik.isSubmitting}
            >
              Save
            </button>
            {JSON.stringify({...resource, resourceName: formik.values.resourceName}) != JSON.stringify(formik.values) && (
              <b className="absolute text-sm bottom-0 text-rose-500">
                Please save before publishing new changes
              </b>
            )}
          </div>
        </form>
      </div>
      <ActivityRightPanel
        activityId={resourceId}
        activityType={ActivityTypes.ResourceSources}
        auditCreatedBy={resource.researcherName}
        auditLastUpdatedBy={resource.lastUpdatedName}
        companyId={formik.values.companyId}
        isVerified={formik.values.isVerified}
        verificationComments={formik.values.verificationComments}
        verifiedByName={formik.values.verifiedByName}
        verifiedOn={formik.values.verifiedOn}
        onChange={formik.handleChange}
      />
    </>
  ) : (
    <div className="overlay">
      <LottieLoader
        lottieType="butterflyLottie"
        style={{ height: "10.25rem", width: "10.25rem" }}
      />
    </div>
  );
};

export default Resource;
