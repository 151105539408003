import { transformNullToString } from './../../../shared/transformStringValue';
import * as Yup from 'yup';
import { transformNumberValue, transformNumberValueAllowZero } from '../../../shared/transformNumberValue';
import { testNumberWithDotLength } from '../../../shared/testNumberWithDotLength';

export const formSchema = Yup.object().shape({
    regionIdentifierId: Yup.number().typeError('Please select region').min(1, 'Please select region').required().integer(),
    medium: Yup.number().typeError('Please select medium').min(0, 'Please select medium').required().integer(),
    shortDescription: Yup.string().nullable(true),
    description: Yup.string().nullable(true),
    comment: Yup.string().transform(transformNullToString).nullable(true),
    resource: Yup.number().typeError('Please select transport resources').min(0, 'Please select transport resources').required().integer(),
    countryId: Yup.number().typeError('Please select country').integer().nullable(true),
    temperatureConditions: Yup.number().typeError('Please select temperature conditions').min(0, 'Please select temperature conditions').required().integer(),
    secondaryDataReferenceSourceType: Yup.number().typeError('Please select sources').min(0, 'Please select sources').required().integer(),
    carbon: Yup.number().transform(transformNumberValueAllowZero).typeError('Carbon is required').required('Carbon is required').test('len', 'Max symbols allowed - 32. Max symbols after dot - 16', testNumberWithDotLength),
    processName: Yup.string().required(),
    processId: Yup.string().required(),
    waterUsage: Yup.number().transform(transformNumberValueAllowZero).typeError('Water usage is required').required('Water usage is required').test('len', 'Max symbols allowed - 32. Max symbols after dot - 16', testNumberWithDotLength),
    eutrophication: Yup.number().transform(transformNumberValueAllowZero).typeError('Eutrophication is required').required('Eutrophication is required').test('len', 'Max symbols allowed - 32. Max symbols after dot - 16', testNumberWithDotLength),
    refQuantity: Yup.number().typeError('Ref. Quantity is required').min(1, 'Ref Quantity can`t be less than 1').max(1, 'Ref Quantity can`t be bigger than 1').required('Ref. Quantity is required'),
    referenceDataPublicationYear: Yup.number().transform(transformNumberValue).typeError('Reference data publication year field is required!').min(1900, 'Year can`t be less than 1900').max(2200, 'Year can`t be greater than 2200').required('Reference data publication year field is required!'),
    referencesSamplingYear: Yup.number().typeError('Please Reference Sampling Year').min(1900, 'Year can`t be less than 1900').max(2200, 'Year can`t be greater than 2200').nullable(true),
    referenceRegionIdentifier: Yup.number().typeError('Please Reference Region Identifier').min(1, 'Please Reference Region Identifier').required('Reference Region IdentifierId is required').integer(),
});
