import {
  IEmissionFactors,
  IConfiguration,
} from "./../modules/emission-factors/emission/types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
interface IState {
  factor: IEmissionFactors;
  loading: boolean;
  error: string;
  configuration: IConfiguration;
  configurationLoading: boolean;
}

const initialState: IState = {
  configuration: {
    productTaxonomies: {
      classes: [],
      categories: [],
      subcategories: [],
      functionalUnits: [{ id: 0, subcategoryId: 0, code: "", name: "" }],
    },
    eutrophicationProxyProduceTaxonomies: {
      classes: [{ id: 0, code: "", name: "" }],
      categories: [],
      subcategories: [],
      functionalUnits: [],
    },
    dataOrigins: [],
    farmDataCalculators: [],
    functionalUnits: [],
    plantProtectionTypes: [],
    plantProtectionTypeGroups: [],
    pnNExcelCalculators: [],
    productionSystems: [],
    postHarvestLossesIds: [],
    productTypeEFs: [],
    regions: [],
    countries: [],
    secondaryDataReferenceSourceType: [],
    missingImpactFactors: [],
    specialTaxonomies: {
      classes: [],
      categories: [],
      functionalUnits: [{ id: 0, categoryId: 0, code: "", name: "" , state: 0}],
    }
  },
  factor: {
    code: null,
    userEmail: "",
    lastUpdatedName: "",
    lastPublishedDate: "",
    researcherName: "",
    researcherEmail: "",
    updated: "",
    state: 0,
    id: 0,
    productType: -1,
    productId: -1,
    productNameAtSource: "",
    countryId: null,
    regionIdentifierId: -1,
    regionStateName: "",
    productionSystem: -1,
    plantProtectionTypeGroup: -1,
    plantProtectionType: -1,
    functionalUnit: -1,
    dataOrigin: -1,
    coreVersionRelease: "",
    externalSource: null,
    farmDataCalculator: null,
    hestiaProduceId: null,
    hestiaSubmissionId: null,
    calculatorVersion: null,
    pnNExcelCalculator: null,
    mondraMAToFillMissingEutrophication: false,
    clickUpId: "",
    landUse: 0,
    biodiversity: 0,
    waterUsageIncludingWasterScarcity: 0,
    postHarvestLossesId: null,
    postHarvestLosses: null,
    postHarvestLossesNotes: [],
    comment: null,
    missingImpactFactor: 0,
    proxyCountryId: null,
    proxyProduceId: null,
    numberOfStudies: -1,
    referenceStudy: "",
    referenceTechnologyProxyContribution: null,
    referenceSourcingCountryProxyContribution: null,
    referenceTechnologyType: "",
    referenceRegionIdentifier: -1,
    carbon: 0,
    waterUsage: 0,
    eutrophication: 0,
    secondaryDataReferenceSourceType: -1,
    dataQualityReliabilityScore: null,
    dataQualityCompletenessScore: null,
    referencesSamplingYear: null,
    referenceDataPublicationYear: null,
    dataQualityComment: "",
    referenceSourcingCountry: null,
    companyId: null,
    isVerified: false,
    verificationComments: null,
    verifiedOn: null,
    verifiedByEmail: null,
    verifiedByName: null,
  },
  loading: true,
  configurationLoading: true,
  error: "",
};

const factorSlice = createSlice({
  name: "factor",
  initialState,
  reducers: {
    factorRequest(state) {
      state.loading = true;
    },
    factorSuccess(state, action: PayloadAction<IEmissionFactors>) {
      state.loading = false;
      state.factor = action.payload;
    },
    factorFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    factorConfigurationRequest(state) {
      state.configurationLoading = true;
    },
    factorConfigurationFailure(state) {
      state.configurationLoading = false;
    },
    configuration(state, action: PayloadAction<IConfiguration>) {
      state.configurationLoading = false;
      state.configuration = action.payload;
    },
    handlePublishStatus(state, action: PayloadAction<number>) {
      state.factor.state = action.payload;
    },
    addNote(state, action) {
      if (state.factor[action.payload.key] == null) {
        state.factor[action.payload.key] = [];
      }
      state.factor[action.payload.key].push(action.payload.value);
    },
    setDefaultFactor: () => initialState,
  },
});

export default factorSlice.reducer;
export const {
  factorRequest,
  factorSuccess,
  factorFailure,
  handlePublishStatus,
  setDefaultFactor,
  configuration,
  addNote,
  factorConfigurationRequest,
  factorConfigurationFailure,
} = factorSlice.actions;
